import { Menu, Transition } from "@headlessui/react";
import {
  FilterList,
  MoreVert,
  PeopleOutline,
  PersonAddAlt,
  PersonOff,
  PersonRemove,
  RemoveRedEye,
} from "@mui/icons-material";
import moment from "moment";
import { Fragment, useState } from "react";
import LinePagination from "src/Paging/LinePagination";
import { useQuery } from "@apollo/client";
import { ADMIN_DASHBOARD } from "../auth/data/queries";

interface User {
  last_name: String;
  first_name: String;
  email: String;
  phone: String;
  date_joined: String;
  status: String;
  gender: String;
  genotype: String;
  relationship_status: String;
  ethinicity: String;
  date_of_birth: Date;
  username: String;
  religion: String;
  occupation: String;
  location: String;
  country: String;
  place_of_birth: String;
  nationality: String;
  qualification: String;
  hobbies: String;
  preferred_gender: String;
  preferred_location: String;
  preferred_religion: String;
  preferred_genotype: String;
  preferred_status: String;
  preferred_age: String;
}
type Alluserdata = User[];
export default function AllAdminHandlers({ setSelectedUser, setIndexPage }) {
  const { data, loading } = useQuery(ADMIN_DASHBOARD, {
    variables: {
      adminId: 1,
    },
    fetchPolicy: "network-only",
  });

  const [allDatas, setAllDatas] = useState<Alluserdata | []>([]);
  const {
    all_users_count,
    all_inactive_users,
    all_blacklisted_users,
    all_handlers_count,
    handlers,
  } = data?.adminDashboard || {};
  return (
    <div className="space-y-10">
      <div className="space-y-4">
        <h2 className="text-base font-bold">User's Summary</h2>
        <div className="grid grid-cols-5 gap-5">
          <div className="bg-white space-y-3 py-4 px-8 rounded-md flex flex-col justify-between">
            <div className="w-10 h-10 rounded-full bg-[#5718FF] flex justify-center items-center text-white">
              <PeopleOutline fontSize="small" />
            </div>
            <p className="text-sm font-normal">All Users</p>
            <h2 className="text-base font-bold">{all_users_count}</h2>
          </div>
          {/* <div className="bg-white space-y-3 py-4 px-8 rounded-md flex flex-col justify-between">
            <div className="w-10 h-10 rounded-full bg-[#93FFCB] flex justify-center items-center text-black">
              <PersonAddAlt fontSize="small" />
            </div>
            <p className="text-sm font-normal">Active Users</p>
            <h2 className="text-base font-bold">250,000</h2>
          </div> */}
          {/* <div className="bg-white space-y-3 py-4 px-8 rounded-md flex flex-col justify-between">
            <div className="w-10 h-10 rounded-full bg-[#CAD5FE] flex justify-center items-center text-black">
              <PersonOff fontSize="small" />
            </div>
            <p className="text-sm font-normal">Inactive Users</p>
            <h2 className="text-base font-bold">45,750</h2>
          </div> */}
          <div className="bg-white space-y-3 py-4 px-8 rounded-md flex flex-col justify-between">
            <div className="w-10 h-10 rounded-full bg-[#FF3366] flex justify-center items-center text-white">
              <PersonRemove fontSize="small" />
            </div>
            <p className="text-sm font-normal">Blacklisted Users</p>
            <h2 className="text-base font-bold">{all_blacklisted_users}</h2>
          </div>
          <div className="bg-white space-y-3 py-4 px-8 rounded-md flex flex-col justify-between">
            <div className="w-10 h-10 rounded-full bg-[#017EFA] flex justify-center items-center text-white">
              <PersonAddAlt fontSize="small" />
            </div>
            <p className="text-sm font-normal">Handlers</p>
            <h2 className="text-base font-bold">{all_handlers_count}</h2>
          </div>
        </div>
      </div>
      <div className="space-y-4 pb-40">
        <h2 className="text-base font-bold">Handlers</h2>
        <LinePagination data={handlers || []} setCurrentItems={setAllDatas}>
          <table className="relative w-full divide-y divide-gray-1 overflow-y-auto  bg-white">
            <thead className=" rounded-lg rounded-t border px-5">
              <tr className="rounded-t-lg">
                <th
                  scope="col"
                  className={`px-2 py-4 pl-5 text-left text-xs font-medium uppercase`}
                >
                  <div className="flex gap-2 items-center">
                    Handler name
                    <Menu as="div" className="w-max relative">
                      {({ open }) => (
                        <>
                          <Menu.Button as="div" className="w-max">
                            <FilterList
                              fontSize="small"
                              className="text-blue-600 cursor-pointer shadow-md"
                            />
                          </Menu.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="absolute normal-case z-50 p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-60 top-full left-0"
                            >
                              <div className="space-y-3 py-3 px-2">
                                <div className="space-y-2">
                                  <p className="text-xs font-medium ">
                                    Firstname
                                  </p>
                                  <input
                                    type="text"
                                    placeholder="Enter firstname"
                                    className="w-full text-sm px-4 py-2 rounded-lg"
                                  />
                                </div>
                                <div className="space-y-2">
                                  <p className="text-xs font-medium ">
                                    Lastname
                                  </p>
                                  <input
                                    type="text"
                                    placeholder="Enter lastname"
                                    className="w-full text-sm px-4 py-2 rounded-lg"
                                  />
                                </div>
                                <div className="space-y-2">
                                  <p className="text-xs font-medium ">Email</p>
                                  <input
                                    type="email"
                                    placeholder="example@email.com"
                                    className="w-full text-sm px-4 py-2 rounded-lg"
                                  />
                                </div>
                                <div className="space-y-2">
                                  <p className="text-xs font-medium ">Date</p>
                                  <input
                                    type="date"
                                    placeholder="Enter date"
                                    className="w-full text-sm px-4 py-2 rounded-lg"
                                  />
                                </div>
                                <div className="space-y-2">
                                  <p className="text-xs font-medium ">
                                    Phone Number
                                  </p>
                                  <input
                                    type="text"
                                    placeholder="Enter phone"
                                    className="w-full text-sm px-4 py-2 rounded-lg"
                                  />
                                </div>
                                <div className="space-y-2">
                                  <p className="text-xs font-medium ">Status</p>
                                  <select className="w-full text-sm px-4 py-2 rounded-lg">
                                    <option value={""}>Select status</option>
                                    <option value={"Pending"}>Pending</option>
                                    <option value={"Active"}>Active</option>
                                    <option value={"Inactive"}>Inactive</option>
                                    <option value={"Blacklisted"}>
                                      Blacklisted
                                    </option>
                                  </select>
                                </div>
                                <div className="flex pt-4 border-t gap-2 items-center">
                                  <button className="px-4 py-2 rounded-lg text-center w-full uppercase bg-white text-[#081A51] border border-[#081A51] text-xs font-normal ">
                                    Reset
                                  </button>
                                  <button className="px-4 py-2 rounded-lg text-center w-full uppercase text-white bg-[#081A51] border border-[#081A51] text-xs font-normal ">
                                    Filter
                                  </button>
                                </div>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                </th>
                {/* <th
                  scope="col"
                  className={`px-2 py-4 pl-5 text-left text-xs font-medium uppercase`}
                >
                  <div className="flex gap-2 items-center">
                    First name
                    <FilterList fontSize="small" />
                  </div>
                </th> */}
                {/* <th
                  scope="col"
                  className={`px-2 py-4 pl-5  text-left text-xs font-medium uppercase`}
                >
                  <div className="flex gap-2 items-center">
                    Email
                    <FilterList fontSize="small" />
                  </div>
                </th> */}
                {/* <th
                  scope="col"
                  className={`px-2 py-4 pl-5  text-left text-xs font-medium uppercase`}
                >
                  <div className="flex gap-2 items-center">
                    Phone number
                    <FilterList fontSize="small" />
                  </div>
                </th>
                <th
                  scope="col"
                  className={`px-2 py-4 pl-5  text-left text-xs font-medium uppercase`}
                >
                  <div className="flex gap-2 items-center">
                    Date joined
                    <FilterList fontSize="small" />
                  </div>
                </th>
                <th
                  scope="col"
                  className={`px-2 py-4 pl-5  text-left text-xs font-medium uppercase`}
                >
                  <div className="flex gap-2 items-center">
                    Status
                    <FilterList fontSize="small" />
                  </div>
                </th> */}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-300 overflow-auto bg-white px-5 pt-5">
              {allDatas?.map?.((item: any, index: number) => (
                <tr
                  onClick={() => {
                    setSelectedUser(item);
                    setIndexPage(1);
                  }}
                  className="hover:bg-gray-100"
                  key={index}
                >
                  <td className="px-2 pl-5 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                    {item?.user_name}
                  </td>
                  {/* <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                    {item?.first_name}
                  </td>
                  <td className="px-2 py-4 text-left text-xs font-normal lowercase text-[#545F7D]">
                    {item?.email}
                  </td>
                  <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                    {item?.phone}
                  </td>
                  <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                    {moment(item?.date_joined).format("MMM DD, YYYY")}
                  </td> */}
                  {/* <td className="px-2 py-4 text-left text-xs font-normal capitalize text-[#545F7D]">
                    <p
                      className={`px-4 py-1 w-max rounded-full ${
                        item?.status?.toLowerCase?.() === "inactive"
                          ? "bg-[#545f7d3b] text-black"
                          : item?.status?.toLowerCase?.() === "pending"
                          ? "bg-[#e9b30042] text-[#E9B200]"
                          : item?.status?.toLowerCase?.() === "active"
                          ? "bg-[#39cd633f] text-[#39CD62] "
                          : "bg-[#e4033b41] text-[#E4033B]"
                      } text-center`}
                    >
                      {item?.status}
                    </p>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </LinePagination>
      </div>
    </div>
  );
}
