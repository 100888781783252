import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderText, ParagraphText } from "./styles";
import { useMutation } from "@apollo/client";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import theme from "src/assets/colors/theme";
import {
  USER_SIGN_IN,
  VERIFY_MAIL_CODE,
} from "src/components/auth/data/mutation";
import { useHandler } from "src/components/auth/hooks/HandlerProvider";
import { useUser } from "src/components/auth/hooks/UserProvider";
import ButtonComp from "src/components/Button/Button";
import InputField from "src/components/Input";
import { useUpdateUserLoginStatus } from "src/hooks/data/mutations";
import ResponsiveOverlay from "src/overlays/ResponsiveOverlay";
import { BeatLoader } from "react-spinners";
import { ErrorOutline } from "@mui/icons-material";

const logo =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/logo.svg";
const People = "https://dearmacmedia.s3.amazonaws.com/assests/assets/LOGIN.png";
const PeopleSm =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/peopleSm.svg";

const Login = () => {
  const [userLogin, setUserLogin] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(true);
  const { setUser, user } = useUser();
  const { logoutHandler } = useHandler();
  const [signIn, { loading }] = useMutation(USER_SIGN_IN);
  const [updateUserLoginStatus] = useUpdateUserLoginStatus();
  const [unverifed, setUnverified] = useState(false);
  const [otpValue, seTOtpValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [verifyMailCode] = useMutation(VERIFY_MAIL_CODE);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserLogin((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOnlineStatus = async (userId) => {
    await updateUserLoginStatus({
      variables: {
        userId: Number(userId),
        isAvailable: true,
        isOnline: true,
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const disabled = Object.values(userLogin).some((text) => text === "");

  const handleSubmit = async () => {
    // e.preventDefault();
    // localStorage.setItem("userToken", userLogin.email);
    // navigate("/dashboard/matches");
    //validate email is valid
    const match = userLogin.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (!match) {
      toast.error("Invalid email address, please check and try again.");
      return;
    }
    await signIn({
      variables: {
        email: userLogin.email,
        password: userLogin.password,
      },
      onCompleted: async (data) => {
        logoutHandler?.();
        handleOnlineStatus(data?.userSignin?.id);
        setUser(data.userSignin);
        navigate("/dashboard/friend-request");
      },
      onError: (error) => {
        if (error?.message?.toLowerCase?.() === "not verified") {
          return setUnverified(true);
        }
        toast.error(error?.message ?? "Network Error");
      },
    });
  };

  const handleVerifyMail = (theMail: any, token: any) => {
    setIsLoading(true);
    verifyMailCode({
      variables: {
        email: theMail,
        token,
      },
      onCompleted: (data) => {
        if (data?.verifyEmail === false) {
          // setIsLoading(false);
          toast.error("Incorrect OTP, kindly check your mail");
          return;
        }
        handleSubmit();
      },
      onError: (error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Error occured. Try again later");
      },
    });
  };

  const handleForgetPassword = () => {
    navigate("/forget-password");
  };
  const handleCreateAccount = () => {
    navigate("/pre-verify");
  };

  return (
    <>
      <ResponsiveOverlay
        isOpen={unverifed}
        setIsOpen={setUnverified}
        title={""}
        size="small"
      >
        <div className=" w-full">
          <div className="w-full mx-auto p-5 pt-10 bg-white flex flex-col justify-center items-center">
            <>
              <div className="w-20 h-20 flex-shrink-0 rounded-full flex justify-center items-center bg-gray-200">
                <ErrorOutline fontSize="large" />
              </div>
              <div className="space-y-2 pt-5">
                <p className="text-[#081A51] text-lg font-bold text-center">
                  You are not verified yet!
                </p>
                <p className="text-[#223333] text-sm text-center">
                  Kindly check your mail for an otp code to complete
                  verification.
                </p>
              </div>
              <div className="pb-10 w-4/5 mx-auto">
                <InputField
                  label={"OTP"}
                  placeholder="Enter Otp"
                  labelStyle="text-sm sm:text-base font-semibold"
                  type="number"
                  name="header"
                  onChange={(e) => seTOtpValue(e.target.value)}
                  value={otpValue}
                  labelView
                />
              </div>
            </>
          </div>
          <div className="flex p-5 w-full justify-end gap-5 bg-gray-50 items-center">
            <button
              disabled={isLoading || otpValue?.trim?.() === ""}
              onClick={() => {
                handleVerifyMail(userLogin.email, otpValue);
              }}
              className="px-4 sm:px-6 py-2 rounded-lg text-center text-xs sm:text-base font-semibold bg-[#081A51] text-white disabled:bg-gray-300 disabled:text-gray-700"
            >
              {isLoading ? (
                <BeatLoader size={10} color="#fff" />
              ) : (
                "Complete Verification"
              )}
            </button>
          </div>
        </div>
      </ResponsiveOverlay>
      <div className="w-full h-screen  flex flex-col justify-center items-center  px-6 md:px-0  md:flex-row md:gap-4 lg:gap-0 mx-auto ">
        {/* image */}
        <div className="hidden md:block md:w-1/2   ">
          <img
            src={People}
            alt="people"
            className=" w-full h-screen object-cover  flex justify-center items-center mx-auto "
          />
        </div>
        {/* text */}
        <div className=" w-full flex flex-col  md:mx-auto justify-center items-center  md:w-1/2 max-w-xs sm:max-w-sm md:max-w-xs  lg:max-w-sm  md:justify-start md:items-start xl:max-w-md ">
          {/* header */}

          <div className="w-full flex flex-col justify-center items-center mx-auto  md:justify-start md:mx-0 md:items-start  ">
            <img src={logo} alt="logo" className="object-contain w-32 " />
            <h1 className="font-['sans-serif'] mt-6  text-brand-1 font-bold text-2xl">
              Login to your Account
            </h1>
            <p className="text-sm mt-1">
              {" "}
              Meet and Connect with like minded Individuals
            </p>
          </div>
          <div className="w-full ">
            <InputField
              label={"Email"}
              secretState={showPassword}
              icon={<EmailOutlinedIcon />}
              placeholder="Johndoe@example.com"
              type="email"
              name="email"
              onChange={handleChange}
              value={userLogin.email}
              labelView
            />
            <InputField
              label={"Password"}
              secretState={showPassword}
              icon={<LockOutlinedIcon />}
              secret={
                !showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />
              }
              changeVisibleState={togglePasswordVisibility}
              type={!showPassword ? "text" : "password"}
              placeholder="************************"
              name="password"
              onChange={handleChange}
              value={userLogin.password}
              labelView
            />
            <p
              className="text-xs lg:text-sm pt-3 flex justify-end cursor-pointer"
              onClick={handleForgetPassword}
            >
              {" "}
              Forgot password?
            </p>

            <ButtonComp
              children="Login"
              size="large"
              isLoading={loading}
              backgroundColor={theme.PRIMARY_DARK}
              onClick={handleSubmit}
              disabled={disabled || loading}
            />
            <ParagraphText className="text-center lg:text-left lg:mt-12 mt-6">
              Not registered yet?{" "}
              <span
                className="text-[#222E50] font-bold"
                onClick={handleCreateAccount}
                style={{
                  cursor: "pointer",
                }}
              >
                Create an account
              </span>
            </ParagraphText>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
