import { useMutation, useQuery } from "@apollo/client";
import { Menu, Transition } from "@headlessui/react";
import {
  ChevronLeft,
  ChevronRight,
  ExpandMore,
  KeyboardBackspace,
  Person,
  PersonOutline,
} from "@mui/icons-material";
import moment from "moment";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import Crown from "src/assets/crown.svg";
import NoChat from "src/assets/no_chat.png";
import {
  RAISE_BLACKLIST,
  STRIKE_USER,
  RESEND_PASSWORD,
} from "src/hooks/data/mutations";
import { HANDLER_GET_USER } from "src/hooks/data/queries";
import ResponsiveOverlay from "src/overlays/ResponsiveOverlay";
import { useHandler } from "../auth/hooks/HandlerProvider";
import AllUsers from "./AllUsers";
import SuggestUser from "./SuggestUser";
import ViewProfile from "./ViewProfile";
import { SideBarContext } from "src/context/SidebarProvider";
import LinePagination from "src/Paging/LinePagination";
import ASSETS from "src/asset";
const NoUser =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/no_user.png";

interface User {
  id: Number;
  last_name: String;
  about_me: String;
  plan: String;
  first_name: String;
  email: String;
  phone: String;
  date_joined: String;
  status: String;
  gender: String;
  genotype: String;
  age: String;
  media: [Object];
  user_match: [Object];
  userStrike: [Object];
  userReport: [Object];
  userBlackList: [Object];
  subscription: any;
  alais: String;
  religion: String;
  occupation: String;
  location_city: String;
  location_country: String;
  origin_country: String;
  ethnicity: String;
  qualification: String;
  hobbies: String;
  preferred_gender: String;
  preferred_country: String;
  preferred_religion: String;
  preferred_genotype: String;
  preferred_status: String;
  preferred_age: String;
}

export default function Index() {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const { handler } = useHandler();
  const { refetch } = useQuery(HANDLER_GET_USER, {
    variables: {
      userId: Number(selectedUser?.id),
    },
    fetchPolicy: "no-cache",
    onCompleted(data) {
      setSelectedUser(data.user);
    },
  });

  const [resendPassword] = useMutation(RESEND_PASSWORD);
  const [strikeUser] = useMutation(STRIKE_USER);
  const [raiseBlacklist] = useMutation(RAISE_BLACKLIST);
  const [indexPage, setIndexPage] = useState(0);
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState(0);
  const [reason, setReason] = useState<string>("");
  const selections = [
    "General Details",
    "Photos",
    "Pending Matches",
    "Matches",
    "Strike Report",
    "Blacklist Report",
  ];
  const [openOverlay, setOpenOverlay] = useState(false);
  const [action, setAction] = useState("strike");
  const [suggestUser, setSuggestUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const tabRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [viewUser, setViewUser] = useState(null);
  const { setIsNav } = useContext(SideBarContext);
  useEffect(() => {
    const handleResize = () => {
      const divElement = tabRef.current;
      if (divElement) {
        // @ts-ignore
        setIsOverflowing(divElement.scrollWidth > divElement.clientWidth);
      }
    };
    handleResize(); // Call initially

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (indexPage !== 0) {
      setIsNav(false);
    } else {
      setIsNav(true);
    }
    return () => {
      setIsNav(true);
    };
  }, [indexPage]);

  const handleButtonClick = (type) => {
    const divElement = tabRef.current;
    if (divElement) {
      //@ts-ignore
      divElement.scrollTo({
        left:
          type === "add"
            ? //@ts-ignore
              divElement.scrollLeft + 100
            : //@ts-ignore
              divElement.scrollLeft - 100,
        behavior: "smooth",
      });
    }
  };

  const enableUserHandler = (action) => {
    if (action === "strike") {
      setIsLoading(true);
      strikeUser({
        variables: {
          handlerId: Number(handler?.id),
          userId: Number(selectedUser?.id),
          reason,
        },
        onCompleted: (data) => {
          //console.log("strike user", { data });
          setReason("");
          setOpenOverlay(false);
          setIsLoading(false);
          toast.success("Strike submitted successfully");
          refetch();
        },
        onError: (error) => {
          // setReason("");
          setIsLoading(false);
          toast.error(error?.message ?? "Error submitting strike");
        },
      });
    } else {
      setIsLoading(true);
      raiseBlacklist({
        variables: {
          handlerId: Number(handler?.id),
          userId: Number(selectedUser?.id),
          reason,
        },
        onCompleted: (data) => {
          setReason("");
          setOpenOverlay(false);
          setIsLoading(false);
          toast.success("Blacklist report submitted successfully");
          refetch();
        },
        onError: (error) => {
          // setReason("");
          setIsLoading(false);
          toast.error(error?.message ?? "Error submitting blacklist report");
        },
      });
    }
    //  console.log({ action, reason, selectedUser: selectedUser?.id, handler: 2 });
  };

  console.log(selectedUser);

  const pending_matches: any = selectedUser?.user_match?.filter(
    (match: any) =>
      match?.user_match_status !== true || match?.user_matched_status !== true
  );
  const matches: any = selectedUser?.user_match?.filter(
    (match: any) =>
      match?.user_match_status === true || match?.user_matched_status === true
  );

  const [filteredData, setFilteredData] = useState([]);

  return (
    <>
      <ResponsiveOverlay
        isOpen={openOverlay}
        setIsOpen={setOpenOverlay}
        title={
          action === "strike"
            ? "Strike user"
            : "Submit report to blacklist user"
        }
      >
        <div className="p-10 pt-5">
          <div className="w-full  space-y-5">
            <textarea
              className="resize-none w-full h-80 p-5 rounded-lg border border-[#102A42] text-sm"
              placeholder={
                action === "strike"
                  ? "Input Reason for strike here"
                  : "Reason for  Blacklisting user"
              }
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            ></textarea>
            <div className="flex justify-center items-center">
              <button
                onClick={() => {
                  enableUserHandler(action);
                }}
                disabled={!reason || isLoading}
                className="px-6 py-2.5 rounded-lg text-white disabled:bg-gray-300 disabled:text-gray-700  bg-[#081A51] text-base font-semibold"
              >
                {isLoading ? (
                  <BeatLoader size={10} color="#081A51" />
                ) : action === "strike" ? (
                  "Strike user"
                ) : (
                  "Submit blacklist report"
                )}
              </button>
            </div>
          </div>
        </div>
      </ResponsiveOverlay>
      {indexPage === 0 ? (
        <AllUsers
          setSelectedUser={setSelectedUser}
          setIndexPage={setIndexPage}
        />
      ) : indexPage === 1 ? (
        !suggestUser ? (
          <div className="space-y-10 pb-20">
            <h2
              onClick={() => {
                setSelectedUser(null);
                setIndexPage(0);
                setSelectedPage(0);
              }}
              className="text-sm w-max flex gap-2 items-center cursor-pointer hover:text-blue-600 font-bold text-[#1C1F37]"
            >
              <KeyboardBackspace fontSize="small" />
              Back to all users
            </h2>
            <div className="flex gap-5 flex-col sm:flex-row items-start justify-between sm:items-center ">
              <h2 className="text-base font-bold">User's Details</h2>
              <div className="flex gap-4 w-full sm:w-max justify-end items-center">
                <button
                  onClick={() => {
                    resendPassword({
                      variables: {
                        handlerId: Number(handler?.id),
                        userId: Number(selectedUser?.id),
                      },
                      onCompleted(data) {
                        if (data.resendPassword) {
                          toast.success("Password sent successfully");
                        } else {
                          toast.error("Error sending password, try again");
                        }
                      },
                    });
                  }}
                  className="px-4 py-2 rounded-lg uppercase bg-white text-[#081A51] border border-[#081A51] text-xs font-normal "
                >
                  Resend Passsword
                </button>
                <button
                  onClick={() =>
                    navigate(
                      `/dashboard/handler/chat?selectedUserID=${selectedUser?.id}`
                    )
                  }
                  className="px-4 py-2 rounded-lg uppercase bg-white text-[#081A51] border border-[#081A51] text-xs font-normal "
                >
                  Message user
                </button>
                <Menu as="div" className="w-max relative">
                  {({ open }) => (
                    <>
                      <Menu.Button as="div" className="w-max">
                        <button
                          onClick={() => {
                            // setSuggestUser(true);
                          }}
                          className="px-4 py-2 flex gap-2 items-center rounded-lg uppercase bg-white text-gray-600 border border-gray-600 text-xs font-normal "
                        >
                          More
                          <ExpandMore fontSize="inherit" />
                        </button>
                      </Menu.Button>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="absolute normal-case p-2 space-y-3 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg z-30 rounded-lg w-60 top-full right-0 origin-top-right"
                        >
                          <div className="space-y-2">
                            <button
                              onClick={() => {
                                setSuggestUser(true);
                              }}
                              className="px-4 py-2 rounded-lg uppercase block w-full bg-white text-[#39CDCC] border border-[#39CDCC] text-xs font-normal "
                            >
                              Suggest user
                            </button>
                            <button
                              onClick={() => {
                                setAction("strike");
                                setOpenOverlay(true);
                              }}
                              className="px-4 py-2 rounded-lg uppercase block w-full bg-white text-[#FF0000] border border-[#FF0000] text-xs font-normal "
                            >
                              Strike user
                            </button>
                            <button
                              onClick={() => {
                                setAction("blacklist");
                                setOpenOverlay(true);
                              }}
                              className="px-4 py-2 rounded-lg uppercase block w-full bg-[#FF0000] text-white text-xs font-normal "
                            >
                              Submit blacklist report
                            </button>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
            <div className="bg-white p-5 space-y-7 pb-0">
              <div className="flex sm:divide-x gap-5 flex-col sm:flex-row items-start divide-gray-200 sm:items-center">
                <div className="flex gap-5 flex-shrink-0 items-center">
                  <div className="w-10 sm:w-16 h-10 text-base sm:text-lg sm:h-16 flex justify-center items-center flex-shrink-0 rounded-full bg-[#21407d53]">
                    <PersonOutline
                      fontSize="inherit"
                      className="text-[#081A51]"
                    />
                  </div>
                  <div className="">
                    <h2 className="text-base capitalize font-semibold">
                      {selectedUser?.first_name + " " + selectedUser?.last_name}
                    </h2>
                    <p className="text-xs font-medium">{selectedUser?.alais}</p>
                  </div>
                </div>
                <div className="pl-5 hidden sm:inline-block flex-shrink-0">
                  <h2 className="text-base font-semibold">User's Plan</h2>
                  <p className="text-xs font-medium">
                    {selectedUser?.subscription?.name ?? "N/A"}
                  </p>
                </div>
                <div className="pl-5  hidden sm:inline-block">
                  <h2 className="text-base font-semibold">About</h2>
                  <p className="text-xs font-medium">
                    {selectedUser?.about_me}
                  </p>
                </div>
              </div>
              <div className="relative">
                {isOverflowing && (
                  <>
                    <span
                      onClick={() => {
                        handleButtonClick("sub");
                      }}
                      style={{ top: "50%", transform: "translateY(-50%)" }}
                      className="w-7 sm:w-10 h-7 sm:h-10 mr-10 flex justify-center z-30 items-center border border-gray-300 rounded-lg cursor-pointer absolute left-0 bg-white shadow-md"
                    >
                      <ChevronLeft fontSize="small" />
                    </span>
                    <span
                      onClick={() => {
                        handleButtonClick("add");
                      }}
                      style={{ top: "50%", transform: "translateY(-50%)" }}
                      className="w-7 sm:w-10 h-7 sm:h-10 ml-10 flex justify-center z-30 items-center border border-gray-300 rounded-lg cursor-pointer absolute right-0 bg-white shadow-md"
                    >
                      <ChevronRight fontSize="small" />
                    </span>
                  </>
                )}

                <div
                  ref={tabRef}
                  className={`flex gap-6 overflow-x-auto pt-5 ${
                    isOverflowing && "px-14"
                  } justify-between items-center`}
                >
                  {selections?.map((selection, index) => (
                    <h2
                      onClick={() => {
                        setSelectedPage(index);
                      }}
                      key={index}
                      className={` border-b flex-shrink-0 w-max text-sm cursor-pointer pb-4 font-medium ${
                        selectedPage === index
                          ? "border-[#D4678F] text-[#D4678F] "
                          : "border-transparent hover:opacity-70"
                      }`}
                    >
                      {selection}
                    </h2>
                  ))}
                </div>
              </div>
            </div>
            {selectedPage === 0 ? (
              <div className="bg-white p-5 space-y-7">
                <h2 className="text-base font-bold text-[#213F7D]">
                  Personal Information
                </h2>
                <div className="space-y-7 divide-y divide-gray-200">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 lg:grid-cols-5 lg:gap-8">
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Full name
                      </p>
                      <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                        {selectedUser?.first_name +
                          " " +
                          selectedUser?.last_name}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Phone number
                      </p>
                      <h2 className="text-sm font-semibold text-[#545F7D]">
                        {selectedUser?.phone}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Email
                      </p>
                      <h2 className="text-sm break-words lowercase font-semibold text-[#545F7D]">
                        {selectedUser?.email}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Gender
                      </p>
                      <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                        {selectedUser?.gender}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Genotype
                      </p>
                      <h2 className="text-sm uppercase font-semibold text-[#545F7D]">
                        {selectedUser?.genotype}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Relationship status
                      </p>
                      <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                        {selectedUser?.status}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Ethnicity
                      </p>
                      <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                        {selectedUser?.ethnicity}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Date of Birth
                      </p>
                      <h2 className="text-sm font-semibold text-[#545F7D]">
                        {selectedUser?.age?.includes?.("/")
                          ? //@ts-ignore
                            moment(selectedUser?.age, "DD/MM/YYYY").format(
                              "DD MMM, YYYY"
                            )
                          : //@ts-ignore
                            moment(new Date(selectedUser?.age)).format(
                              "DD MMM, YYYY"
                            ) ?? "N/A"}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        alais
                      </p>
                      <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                        {selectedUser?.alais}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Religion
                      </p>
                      <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                        {selectedUser?.religion}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Occupation
                      </p>
                      <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                        {selectedUser?.occupation}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Location
                      </p>
                      <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                        {selectedUser?.location_city}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Country
                      </p>
                      <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                        {selectedUser?.location_country}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Place of Birth
                      </p>
                      <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                        {selectedUser?.origin_country}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Ethnicity
                      </p>
                      <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                        {selectedUser?.ethnicity}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Qualification
                      </p>
                      <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                        {selectedUser?.qualification}
                      </h2>
                    </div>
                    <div className="">
                      <p className="text-10 uppercase font-normal text-[#545F7D] ">
                        Hobbies
                      </p>
                      <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                        {selectedUser?.hobbies}
                      </h2>
                    </div>
                  </div>
                  <div className="space-y-7 pt-7">
                    <h2 className="text-base font-bold text-[#213F7D]">
                      Preferences
                    </h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 lg:grid-cols-5 lg:gap-8">
                      <div className="">
                        <p className="text-10 uppercase font-normal text-[#545F7D] ">
                          Gender
                        </p>
                        <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                          {selectedUser?.preferred_gender}
                        </h2>
                      </div>
                      <div className="">
                        <p className="text-10 uppercase font-normal text-[#545F7D] ">
                          Location
                        </p>
                        <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                          {selectedUser?.preferred_country}
                        </h2>
                      </div>
                      <div className="">
                        <p className="text-10 uppercase font-normal text-[#545F7D] ">
                          Religion
                        </p>
                        <h2 className="text-sm capitalize font-semibold text-[#545F7D]">
                          {selectedUser?.preferred_religion}
                        </h2>
                      </div>
                      <div className="">
                        <p className="text-10 uppercase font-normal text-[#545F7D] ">
                          Genotype
                        </p>
                        <h2 className="text-sm uppercase font-semibold text-[#545F7D]">
                          {selectedUser?.preferred_genotype}
                        </h2>
                      </div>
                      <div className="">
                        <p className="text-10 uppercase font-normal text-[#545F7D] ">
                          Relationship status
                        </p>
                        <h2 className="text-sm uppercase font-semibold text-[#545F7D]">
                          {selectedUser?.preferred_status}
                        </h2>
                      </div>
                      <div className="">
                        <p className="text-10 uppercase font-normal text-[#545F7D] ">
                          Age
                        </p>
                        <h2 className="text-sm font-semibold text-[#545F7D]">
                          {selectedUser?.preferred_age}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : selectedPage === 1 ? (
              <div className="bg-white p-5 space-y-7">
                <h2 className="text-base font-bold text-[#213F7D]">
                  User's Photos
                </h2>
                {
                  //@ts-ignore
                  selectedUser?.media?.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                      {selectedUser?.media?.map((asset: any) => (
                        <div
                          className="w-full h-40 border border-gray-600 rounded-lg"
                          key={asset.id}
                        >
                          <img
                            alt="images"
                            src={asset?.url}
                            className="w-full object-cover h-full rounded-lg"
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className=" items-center justify-center w-full h-60 bg-white flex">
                      <div className=" flex gap-5 flex-col justify-center items-center">
                        <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                          <img
                            src={NoChat}
                            alt="empty"
                            className="w-5 md:w-10 h-5 md:h-10 object-contain"
                          />
                        </div>
                        <h2 className="text-lg text-center font-bold text-[#081A51] ">
                          No Photos available
                        </h2>
                        <p className="text-sm w-4/5 mx-auto text-center">
                          This user doesn't have any photo in their media
                        </p>
                      </div>
                    </div>
                  )
                }
              </div>
            ) : selectedPage === 2 ? (
              <div className="bg-white p-5 space-y-7">
                <h2 className="text-base font-bold text-[#213F7D]">
                  User's Pending Matches
                </h2>
                {pending_matches?.length > 0 ? (
                  // <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
                  //   {pending_matches?.map((match: any, index) => {
                  //     return (
                  //       <div
                  //         key={index}
                  //         className=" rounded-lg bg-[#EBF1FF] hover:bg-[#FDEDE5] flex flex-col gap-4 px-4 py-6 shadow"
                  //       >
                  //         <div className="w-16 h-16 border border-gray-300 shadow-md flex justify-center items-center max-lg:w-10 relative max-lg:h-10 rounded-full bg-white self-center">
                  //           {match?.user_matched?.profile_image_url ? (
                  //             <img
                  //               src={match?.user_matched?.profile_image_url}
                  //               alt="person"
                  //               className="object-cover w-full h-full rounded-full"
                  //             />
                  //           ) : (
                  //             <Person fontSize="small" />
                  //           )}
                  //           <div className="w-6 h-6 border border-gray-300 rounded-full bg-white absolute bottom-0 right-0 flex justify-center items-center ">
                  //             <img
                  //               src={Crown}
                  //               alt="crown"
                  //               className="w-3 h-3"
                  //             />
                  //           </div>
                  //         </div>
                  //         <div className="flex flex-col items-center">
                  //           <p className="font-bold capitalize text-center whitespace-nowrap text-sm">
                  //             {match?.user_matched?.first_name}
                  //           </p>
                  //           {/* <div className="flex gap-2 items-center">
                  //             <img
                  //               src={BriefCase}
                  //               alt="briefcase"
                  //               className="w-3 h-3"
                  //             />
                  //             <p className=" text-xs whitespace-nowrap ">
                  //               {match?.user_matched?.occupation}
                  //             </p>
                  //           </div> */}
                  //         </div>

                  //         <button
                  //           //@ts-ignore
                  //           onClick={() => {
                  //             setViewUser(match?.user_matched);
                  //             setIndexPage(2);
                  //           }}
                  //           className="bg-white self-center w-max text-xs font-bold rounded-full px-6 py-2 flex items-center justify-center cursor-pointer"
                  //         >
                  //           View Profile
                  //         </button>
                  //       </div>
                  //     );
                  //   })}
                  // </div>
                  <div className="border">
                    <div
                      style={{ minHeight: "24rem" }}
                      className="w-full min-h-96 relative z-10 bg-white overflow-x-auto"
                    >
                      <table className="relative w-full divide-y divide-gray-1 overflow-auto  bg-white">
                        <thead className=" rounded-lg bg-gray-50 rounded-t border px-5">
                          <tr className="rounded-t-lg">
                            <th
                              scope="col"
                              className={`px-2 py-4 pl-4 text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className={`px-2 py-4  text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                            >
                              {selectedUser?.first_name}'s Status
                            </th>
                            <th
                              scope="col"
                              className={`px-2 py-4  text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                            >
                              Second User Status
                            </th>
                            <th
                              scope="col"
                              className={`px-2 py-4  text-left text-[10px] font-medium uppercase whitespace-nowrap`}
                            >
                              Handler
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-300 w-full overflow-auto bg-white px-5 pt-5">
                          {pending_matches?.length > 0 ? (
                            pending_matches?.map?.(
                              (item: any, index: number) => {
                                return (
                                  <tr
                                    onClick={() => {
                                      setViewUser(item?.user_matched);
                                      setIndexPage(2);
                                    }}
                                    className="hover:bg-gray-100"
                                    key={index}
                                  >
                                    <td className="px-2 py-2 pl-4 whitespace-nowrap text-left text-xs font-semibold capitalize text-[#545F7D]">
                                      <div className="flex gap-2 items-center">
                                        <img
                                          src={
                                            item?.user_matched
                                              ?.profile_image_url ?? ASSETS?.Avi
                                          }
                                          className="rounded-full flex-shrink-0 object-cover w-6 h-6 sm:w-8 sm:h-8"
                                          alt="person"
                                        />
                                        {item?.user_matched?.last_name?.toLowerCase?.() +
                                          " " +
                                          item?.user_matched?.first_name?.toLowerCase?.()}
                                      </div>
                                    </td>
                                    <td
                                      className={`px-2 py-4 whitespace-nowrap text-left text-xs font-normal capitalize ${
                                        item?.user_match_status === null
                                          ? "text-yellow-600"
                                          : item?.user_match_status === false
                                          ? "text-red-600"
                                          : "text-green-600"
                                      }`}
                                    >
                                      {item?.user_match_status === null
                                        ? "Pending"
                                        : item?.user_match_status === false
                                        ? "Rejected"
                                        : "Accepted"}
                                    </td>
                                    <td
                                      className={`px-2 py-4 whitespace-nowrap text-left text-xs font-normal capitalize ${
                                        item?.user_matched_status === null
                                          ? "text-yellow-600"
                                          : item?.user_matched_status === false
                                          ? "text-red-600"
                                          : "text-green-600"
                                      }`}
                                    >
                                      {item?.user_matched_status === null
                                        ? "Pending"
                                        : item?.user_matched_status === false
                                        ? "Rejected"
                                        : "Accepted"}
                                    </td>
                                    <td
                                      className={`px-2 text-purple-600 py-4 whitespace-nowrap text-left text-xs font-normal capitalize `}
                                    >
                                      {item?.user_matched?.handler?.user_name ||
                                        "--"}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr>
                              <td colSpan={8}>
                                <div className=" items-center justify-center w-full h-80 bg-white flex">
                                  <div className="h-full flex gap-5 flex-col justify-center items-center">
                                    <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                                      <img
                                        src={NoUser}
                                        alt="empty"
                                        className="w-5 md:w-10 h-5 md:h-10 object-contain"
                                      />
                                    </div>
                                    <h2 className="text-lg text-center font-bold text-[#081A51] ">
                                      No users found
                                    </h2>
                                    <p className="text-sm w-4/5 mx-auto text-center">
                                      No data for the selections, kindly change
                                      your filters
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className=" items-center justify-center w-full h-60 bg-white flex">
                    <div className=" flex gap-5 flex-col justify-center items-center">
                      <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                        <img
                          src={NoChat}
                          alt="empty"
                          className="w-5 md:w-10 h-5 md:h-10 object-contain"
                        />
                      </div>
                      <h2 className="text-lg text-center font-bold text-[#081A51] ">
                        No pending matches available
                      </h2>
                      <p className="text-sm w-4/5 mx-auto text-center">
                        This user doesn't have pending request
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ) : selectedPage === 3 ? (
              <div className="bg-white p-5 space-y-7">
                <h2 className="text-base font-bold text-[#213F7D]">
                  Matched Profiles
                </h2>
                {matches?.length > 0 ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
                    {matches?.map((match, index) => (
                      <div
                        key={index}
                        className=" rounded-lg bg-[#EBF1FF] hover:bg-[#FDEDE5] flex flex-col gap-4 px-4 py-6 shadow"
                      >
                        <div className="w-16 h-16 border border-gray-300 shadow-md flex justify-center items-center max-lg:w-10 relative max-lg:h-10 rounded-full bg-white self-center">
                          {match?.user_matched?.profile_image_url ? (
                            <img
                              src={match?.user_matched?.profile_image_url}
                              alt="person"
                              className="object-cover w-full h-full rounded-full"
                            />
                          ) : (
                            <Person fontSize="small" />
                          )}
                          <div className="w-6 h-6 border border-gray-300 rounded-full bg-white absolute bottom-0 right-0 flex justify-center items-center ">
                            <img src={Crown} alt="crown" className="w-3 h-3" />
                          </div>
                        </div>
                        <div className="flex flex-col items-center">
                          <p className="font-bold capitalize text-center whitespace-nowrap text-sm">
                            {match?.user_matched?.first_name}
                          </p>
                          {/* <div className="flex gap-2 items-center">
                            <img
                              src={BriefCase}
                              alt="briefcase"
                              className="w-3 h-3"
                            />
                            <p className=" text-xs whitespace-nowrap ">
                              {match?.user_matched?.occupation}
                            </p>
                          </div> */}
                        </div>
                        <button //@ts-ignore
                          onClick={() => {
                            setViewUser(match?.user_matched);
                            setIndexPage(2);
                          }}
                          className="bg-white self-center w-max text-xs font-bold rounded-full px-6 py-2 flex items-center justify-center cursor-pointer"
                        >
                          View Profile
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className=" items-center justify-center w-full h-60 bg-white flex">
                    <div className=" flex gap-5 flex-col justify-center items-center">
                      <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                        <img
                          src={NoChat}
                          alt="empty"
                          className="w-5 md:w-10 h-5 md:h-10 object-contain"
                        />
                      </div>
                      <h2 className="text-lg text-center font-bold text-[#081A51] ">
                        No matches available
                      </h2>
                      <p className="text-sm w-4/5 mx-auto text-center">
                        This user doesn't have matches at the moment
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ) : selectedPage === 4 ? (
              <div className="bg-white p-5 space-y-7">
                <h2 className="text-base font-bold text-[#213F7D]">
                  All Strikes
                </h2>
                {
                  //@ts-ignore
                  selectedUser?.userStrike?.length > 0 ? (
                    <div className="divide-y divide-gray-300">
                      {selectedUser?.userStrike?.map(
                        (strike: any, ind: number) => (
                          <div
                            key={ind}
                            className="flex py-1.5 gap-5 items-start"
                          >
                            <div className="w-4 h-4 mt-1 flex-shrink-0 flex justify-center items-center rounded-full bg-[#D4678F]">
                              <div
                                style={{ width: "50%", height: "50%" }}
                                className="bg-white rounded-full"
                              ></div>
                            </div>
                            <div className="space-y-3 w-full">
                              <div className="flex flex-col gap-1 sm:flex-row items-start  w-full justify-between sm:items-center">
                                <h2 className="text-sm font-semibold">
                                  Handler Strike {ind + 1}
                                </h2>
                                <p className="w-max text-10 bg-[#fe55773f] text-[#FE5578] px-4 py-1 rounded-full">
                                  {moment(strike?.created_at).format(
                                    "DD MMM, YYYY"
                                  )}
                                </p>
                              </div>
                              <p className="text-sm">{strike?.reason}</p>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <div className=" items-center justify-center w-full h-60 bg-white flex">
                      <div className=" flex gap-5 flex-col justify-center items-center">
                        <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                          <img
                            src={NoChat}
                            alt="empty"
                            className="w-5 md:w-10 h-5 md:h-10 object-contain"
                          />
                        </div>
                        <h2 className="text-lg text-center font-bold text-[#081A51] ">
                          No stikes available
                        </h2>
                        <p className="text-sm w-4/5 mx-auto text-center">
                          This user doesn't have strikes at the moment
                        </p>
                      </div>
                    </div>
                  )
                }
              </div>
            ) : (
              <div className="bg-white p-5 space-y-7">
                <h2 className="text-base font-bold text-[#213F7D]">
                  All Blacklist
                </h2>
                {
                  //@ts-ignore
                  selectedUser?.userBlackList?.length > 0 ? (
                    <div className="divide-y divide-gray-300">
                      {selectedUser?.userBlackList?.map(
                        (blacklist: any, index: number) => (
                          <div
                            key={index}
                            className="flex py-1.5 gap-5 items-start"
                          >
                            <div className="w-4 h-4 mt-1 flex-shrink-0 flex justify-center items-center rounded-full bg-[#D4678F]">
                              <div
                                style={{ width: "50%", height: "50%" }}
                                className="bg-white rounded-full"
                              ></div>
                            </div>
                            <div className="space-y-3 w-full">
                              <div className="flex flex-col gap-1 sm:flex-row items-start  w-full justify-between sm:items-center">
                                <h2 className="text-sm font-semibold">
                                  Handler Blacklist {index + 1}
                                </h2>
                                <div className="flex gap-2 items-center">
                                  <p className="w-max text-10 bg-[#fe55773f] text-[#FE5578] px-4 py-1 rounded-full">
                                    {moment(blacklist?.created_at).format(
                                      "DD MMM, YYYY"
                                    )}
                                  </p>
                                  <p
                                    className={`w-max text-10 ${
                                      blacklist?.approved
                                        ? "text-[#39CDCC]"
                                        : "text-yellow-600"
                                    } px-4 py-1 rounded-full`}
                                  >
                                    {blacklist?.approved === true
                                      ? "Approved"
                                      : "Not Approved"}
                                  </p>
                                </div>
                              </div>
                              <p className="text-sm">{blacklist?.reason}</p>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <div className=" items-center justify-center w-full h-60 bg-white flex">
                      <div className=" flex gap-5 flex-col justify-center items-center">
                        <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                          <img
                            src={NoChat}
                            alt="empty"
                            className="w-5 md:w-10 h-5 md:h-10 object-contain"
                          />
                        </div>
                        <h2 className="text-lg text-center font-bold text-[#081A51] ">
                          No blacklist available
                        </h2>
                        <p className="text-sm w-4/5 mx-auto text-center">
                          This user doesn't have blacklist at the moment
                        </p>
                      </div>
                    </div>
                  )
                }
              </div>
            )}
          </div>
        ) : (
          <SuggestUser
            setSuggestUser={setSuggestUser}
            user_id={selectedUser?.id}
          />
        )
      ) : (
        <div className="space-y-5">
          <h2
            onClick={() => {
              setViewUser(null);
              setIndexPage(1);
            }}
            className="text-sm w-max flex gap-2 items-center cursor-pointer hover:text-blue-600 font-bold text-[#1C1F37]"
          >
            <KeyboardBackspace fontSize="small" />
            Go Back
          </h2>
          <ViewProfile user={viewUser} isHandler />
        </div>
      )}
    </>
  );
}
