import {
  createContext,
  FC,
  ReactElement,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useCurrencyHook } from "src/context/hooks/useCurrency";
import { env } from "src/helpers";

const CurrencyContext = createContext<{
  eliteMonth: string;
  eliteBi: string;
  eliteYear: string;
  premiumMonth: string;
  premiumBi: string;
  premiumQuater: string;
  premiumYear: string;
  premiumBlackBi: string;
  premiumBlackYear: string;
  premiumBlackMonth: string;
  country: string;
}>({
  eliteMonth: "",
  eliteBi: "",
  eliteYear: "",
  premiumMonth: "",
  premiumBi: "",
  premiumQuater: "",
  premiumYear: "",
  premiumBlackBi: "",
  premiumBlackYear: "",
  premiumBlackMonth: "",
  country: "",
});

export const useCurrency = () => useContext(CurrencyContext);

export const CurrencyProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const getAmount = useCurrencyHook();
  const [country, setCountry] = useState("others");
  const [eliteBi, setEliteBi] = useState("");
  const [eliteYear, setEliteYear] = useState("");
  const [eliteMonth, setEliteMonth] = useState("");

  const [premiumMonth, setPremiumMonth] = useState("");
  const [premiumBi, setPremiumBi] = useState("");
  const [premiumYear, setPremiumYear] = useState("");
  const [premiumQuater, setPremiumQuater] = useState("");

  const [premiumBlackMonth, setPremiumBlackMonth] = useState("");
  const [premiumBlackBi, setPremiumBlackBi] = useState("");
  const [premiumBlackYear, setPremiumBlackYear] = useState("");

  const fetchLocation = async () => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/geolocation/v1/geolocate?key=${env(
          "geo_api_key"
        )}`,
        {
          method: "POST",
        }
      );
      const data = await response.json();
      const { lat, lng } = data.location;
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${env(
        "geo_api_key"
      )}`;

      const newResponse = await fetch(url);
      const newData = await newResponse.json();

      const country_arr = newData?.results[0]?.formatted_address?.split(", ");
      const country = country_arr?.pop();
      // const state = country_arr?.pop();
      // const city = country_arr?.pop();
      setCountry(country?.toLowerCase?.());
    } catch (error) {
      console.error("Error fetching location:", error);
      setCountry("others");
    }
  };

  useEffect(() => {
    fetchLocation();
  }, []);
  useEffect(() => {
    if (country === "nigeria") {
      getAmount("10500-NGN", setEliteMonth, country);
      getAmount("49000-NGN", setEliteBi, country);
      getAmount("77000-NGN", setEliteYear, country);

      getAmount("40000-NGN", setPremiumMonth, country);
      getAmount("120000-NGN", setPremiumBi, country);
      getAmount("90000-NGN", setPremiumQuater, country);
      getAmount("200000-NGN", setPremiumYear, country);

      getAmount("26250-NGN", setPremiumBlackMonth, country);
      getAmount("67500-NGN", setPremiumBlackBi, country);
      getAmount("120000-NGN", setPremiumBlackYear, country);
    } else {
      getAmount("10-USD", setEliteMonth, country);
      getAmount("49-USD", setEliteBi, country);
      getAmount("77-USD", setEliteYear, country);

      getAmount("40-USD", setPremiumMonth, country);
      getAmount("120-USD", setPremiumBi, country);
      getAmount("90-USD", setPremiumQuater, country);
      getAmount("200-USD", setPremiumYear, country);

      getAmount("26-USD", setPremiumBlackMonth, country);
      getAmount("67-USD", setPremiumBlackBi, country);
      getAmount("120-USD", setPremiumBlackYear, country);
    }
  }, [country]);

  const values = useMemo(
    () => ({
      eliteMonth,
      eliteBi,
      eliteYear,
      premiumMonth,
      premiumBi,
      premiumYear,
      premiumQuater,
      premiumBlackMonth,
      premiumBlackBi,
      premiumBlackYear,
      country,
    }),
    [
      eliteMonth,
      eliteBi,
      eliteYear,
      premiumMonth,
      premiumBi,
      premiumYear,
      premiumQuater,
      premiumBlackMonth,
      premiumBlackBi,
      premiumBlackYear,
      country,
    ]
  );

  return (
    <CurrencyContext.Provider value={values}>
      {children}
    </CurrencyContext.Provider>
  );
};
