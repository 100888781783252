import React, { useEffect, useState } from 'react';
import Requests from './Requests';
import Calender from './Calender';
import UpcomingEvents from './UpcomingEvents';
import { FaLink, FaVideoSlash } from 'react-icons/fa6';
import { FaTimes } from "react-icons/fa";
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import { useUser } from '../auth/hooks/UserProvider';
import { useMutation, useQuery } from '@apollo/client';
import { GET_UPCOMING_EVENTS } from '../auth/data/queries';
import moment from 'moment-timezone';
import { selectClasses } from '@mui/material';
import {BLIND_DATE_REVIEW, CANCEL_BLIND_DATE, TERMINATE_BLIND_DATE } from '../auth/data/mutation';
import Iframe from "react-iframe";
import { MdCancel, MdRateReview } from 'react-icons/md';

function Datefeature() {
  const { user } = useUser();
  const { data, loading, refetch } = useQuery(GET_UPCOMING_EVENTS, {
    variables: {
       getBlindDateUpcomingMatchesId: Number(user?.id),
    },
    fetchPolicy: "no-cache"
  });

  interface Meeting {
  user_match_meeting_time: string; 
  meeting_link:string;
   meeting_id:string;
   user_matched_timezone: string;
   user_match_timezone:string;
   match_user_id:number

}

// console.log("upcomingddddd" , data)
  const [modal, setModal] = useState(false);
  const [isViewing, setIsViewing] = useState(true);
  const [isCanceling, setIsCanceling] = useState(false);
  const [userData, setUserData] = useState<any>([]);
  const [selectedDate,setSelectedDate] = useState<any>("")
  const [isLoading,setIsLoading] = useState(false)
  const [latestMeeting, setLatestMeeting] = useState<Meeting | null>(null);
    const [isFeedback,setIsFeedback] = useState<boolean>(false)
  const [meetLink,setMeetLink] = useState<any>("")
const [openFrame,setOpenFrame] =  useState<boolean>(false)
const [endTime,setEndTime] = useState<any>("")
  const [message, setMessage] = useState('')
  const [charCount, setCharCount] = useState(0)
  const [userTime,setUserTime] = useState<any>("")


  const calculateAge = (birthdate) => {
    const birthDate = new Date(birthdate);
    const ageDiffMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiffMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };
// console.log("setSelectedDate",selectedDate)
function occupationArticle(occupation: string): string {
  const vowels = ['a', 'e', 'i', 'o', 'u'];
  const firstChar = occupation?.toLowerCase()[0];

  if (vowels.includes(firstChar)) {
    return 'an';
  } else {
    return 'a';
  }}
useEffect(()=>{
  setUserData(data?.getBlindDateUpcomingMatches)
if (data?.getBlindDateUpcomingMatches) {
    // Filter out past meetings
    const upcomingMeetings = data.getBlindDateUpcomingMatches.filter((meeting: any) => {
      return moment(meeting?.user_match_meeting_time)?.isAfter(moment());
    });

    // Find the closest future meeting
    const sortedMeetings = upcomingMeetings.sort((a: any, b: any) => {
      return moment(a?.user_match_meeting_time).diff(moment(b?.user_match_meeting_time));
    });

    setLatestMeeting(sortedMeetings[0] || null);

  }
},[data])


  const [cancelblinddate] = useMutation(
CANCEL_BLIND_DATE
)
  const [terminateblinddate] = useMutation(
TERMINATE_BLIND_DATE 
)

 const [reviewblinddate] = useMutation(
BLIND_DATE_REVIEW 
)

const handleCancel = (id)=>{
  setIsLoading(true)
cancelblinddate(
  { variables:{
 "payload": {
    "match_id": Number(id)
  }
  },
  onCompleted:(data)=>{
  refetch()
  setIsLoading(false)
  setModal(false)
  toast.success("Date Canceled Successfull")
},
onError:(error)=>{
  setIsLoading(false)
  toast.error(error?.message)
}
   
}
  )
}

const handletermination = ()=>{
  setIsLoading(true)
terminateblinddate(
  { variables:{
 meetingId: latestMeeting?.meeting_id
  },
  onCompleted:(data)=>{
  
},
onError:(error)=>{
  toast.error(error?.message)
}
   
}
  )
}

const postreview = ()=>{
console.log("see",latestMeeting)
setIsLoading(true)
reviewblinddate(
  { variables:{  "blindDateReviewId": Number(latestMeeting?.meeting_id),
  "userId": Number(user?.id),  "message": message,
  "time": new Date()
},
  onCompleted:(data)=>{
  refetch()
  toast.success("Your feedback is appreciated")
},
onError:(error)=>{
  toast.error(error?.message)
}
   
}
  )
}







const handleJoin = () => {
  // Calculate time and set state based on conditions
  let time1;
  if (Number(latestMeeting?.match_user_id) === Number(user?.id)) {
    // @ts-ignore
    time1 = moment().tz(latestMeeting.user_match_timezone);
  } else {
    // @ts-ignore
    time1 = moment().tz(latestMeeting?.user_matched_timezone);
  }
  setUserTime(time1);
};

useEffect(() => {
  if (!userTime || !latestMeeting) return;

  const meetingTime = moment.utc(latestMeeting?.user_match_meeting_time);
  const date1 = meetingTime?.format('YYYY-MM-DD');
  const date2 = userTime?.format('YYYY-MM-DD');
  const time1 = meetingTime?.subtract(1, 'minutes').format('HH:mm:ss');
  const time2 = userTime?.format('HH:mm:ss');
  
  if (date1 === date2) {
    if (time1 > time2) {
      toast.error('You can\'t join the meeting now as the time isn\'t due yet');
    } else {
      setMeetLink(latestMeeting?.meeting_link);
      setOpenFrame(true);
      setEndTime(meetingTime?.add(7, 'minutes').format('h:mm A'));
      setTimeout(() => {
        handleTerminate();
      }, 7 * 60 * 1000);
    }
  } else if (date1 > date2) {
    toast.error('You can\'t join the meeting now as the time isn\'t due yet.');
  } else {
    setMeetLink(latestMeeting?.meeting_link);
    setOpenFrame(true);
    setEndTime(meetingTime?.add(7, 'minutes').format('h:mm A'));
    setTimeout(() => {
      handleTerminate();
    }, 7 * 60 * 1000);
  }
}, [userTime]); 
console.log("tiemzone",latestMeeting?.user_match_timezone)

  const handleTerminate = ()=>{
    setOpenFrame(false)
    setEndTime("")
    setMeetLink("")
    handletermination()
    setIsFeedback(true)
    setIsLoading(false)
  }

   const maxCharCount = 150
    const handleDescriptionChange = (e) => {
    setCharCount(e.target.value.length)
  }

  const handleReview =()=>{
    if(message === ""){
      toast.warning("Please input your review ")
    }else{
postreview()
setIsFeedback(false)
setIsLoading(false)
    }
  }
  return (

    <main className="space-y-6 pb-10 md:pb-0 ">
      {modal && (
        <div className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-70 z-50 flex justify-center items-center px-6">
          {isViewing && (
            <div className="bg-white pb-6 md:pb-8 xl:pb-10 flex flex-col items-center justify-center mx-auto max-w-md md:max-w-xl xl:max-w-2xl space-y-6">
              <div className="bg-primary-bg py-3 sm:py-4 md:py-5 px-2 sm:px-4 md:px-6 w-full flex items-center justify-between gap-2 ">
                <div className="text-white w-2/3 space-y-1">
                  <h1 className="text-sm font-bold md:text-base xl:text-lg">
                    {selectedDate?.
user_match_theme}</h1>
                  <p className="text-xs lg:text-sm">
                    Ready to try love at first sight? then meet up with your blind date on the {moment.utc(selectedDate?.user_match_meeting_time).format('Do MMMM YYYY, hh:mma')}
                  </p>
                </div>
                <FaTimes
                  onClick={() => setModal(false)}
                  className="text-white text-3xl cursor-pointer lg:text-4xl flex-shrink-0"
                />
              </div>
              {/* image */}
              <div className="w-14 md:w-20 xl:w-28">
                <img src="/images/hidden.png" alt="" />
              </div>
              {/* text */}
              <div className="text-center space-y-2">
                <h1 className="font-bold text-base sm:text-lg md:text-xl lg:text-xl xl:text-2xl">{selectedDate?.user_match?.user_matched?.alais}</h1>
              
                 <p className='text-xs px-6 md:text-sm xl:text-base '>{selectedDate?.user_match?.user_matched?.alais} is {occupationArticle(selectedDate?.user_match?.user_matched?.occupation)} {selectedDate?.user_match?.user_matched?.occupation} aged {selectedDate?.user_match?.user_matched?.age?.includes?.("-")
    ? //@ts-ignore
      calculateAge(selectedDate?.user_match?.user_matched?.age)
      ? //@ts-ignore
        calculateAge(selectedDate?.user_match?.user_matched?.age)
      : ""
    : moment().diff(
        //@ts-ignore
        moment(selectedDate?.user_match?.user_matched?.age, "DD/MM/YYYY"),
        "years"
      ) ?? ""}. {selectedDate?.user_match?.user_matched?.gender === 'Male' ? 'He' : 'She'} is from {selectedDate?.user_match?.user_matched?.origin_state}, {selectedDate?.user_match?.user_matched?.origin_country} and currently lives in {selectedDate?.user_match?.user_matched?.location_state} , {selectedDate?.user_match?.user_matched?.location_country}</p>
              </div>
              <button
                onClick={() => {
                  setIsCanceling(true);
                  setIsViewing(false);
                }}
                className="text-white bg-primary-bg px-6 rounded-md py-2 lg:py-3 text-xs xl:text-base font-medium hover:bg-red-500 hover:text-white hover:shadow-md flex-grow"
              >
                Cancel Appointment
              </button>
            </div>
          )}
          {isCanceling && (
            <div className="bg-white py-6 md:py-8 xl:py-10 rounded-md flex flex-col items-center justify-center mx-auto max-w-sm md:max-w-lg xl:max-w-2xl space-y-6">
              {/* image */}
              <div className="w-14 h-14 md:h-20 md:w-20 xl:w-28 xl:h-28 bg-red-500 bg-opacity-50 rounded-full flex justify-center items-center">
                <FaTimes className="text-2xl text-red-500 md:text-4xl xl:text-6xl" />
              </div>
              {/* text */}
              <div className="text-center space-y-2">
                <h1 className="font-bold text-base sm:text-lg md:text-xl lg:text-xl xl:text-2xl">Cancel Appointment</h1>
                <p className="text-xs px-6 md:text-sm xl:text-base">
                  Are you sure you want to cancel your blind date appointment with Joshua Seeliuman? . Going ahead this will
                  cancel your date reservation
                </p>
              </div>
              <div className="flex items-center gap-4">
                <button
                  onClick={() => {
                    setIsCanceling(false);
                    setIsViewing(true);
                  }}
                  className="text-white bg-primary-bg px-6 rounded-md py-2 lg:py-3 text-xs xl:text-base font-medium hover:bg-opacity-80 hover:text-white hover:shadow-md flex-grow"
                >
                  Don't Proceed
                </button>
                <button disabled={isLoading} onClick={()=>handleCancel(selectedDate?.id)} className="text-primary-bg border-primary-bg border px-6 rounded-md py-2 lg:py-3 text-xs xl:text-base font-medium hover:bg-red-500 hover:text-white hover:shadow-md hover:border-0 flex-grow">
                 {isLoading ? "Canceling..." : "Proceed"}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
        {
        isFeedback && <div style={{zIndex:70}}  className='fixed  top-0 bottom-0 left-0 right-0 bg-black bg-opacity-70 z-50 flex justify-center items-center px-6'>
<div className='bg-white  flex flex-col items-center justify-center mx-auto max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl  xl:max-w-3xl space-y-6 w-full py-6 lg:py-10 px-6 sm:px-10 lg:px-14  relative'>
<div onClick={()=>setIsFeedback(false)} className='cursor-pointer absolute  -top-8  sm:-top-10 -right-4 lg:-right-8 flex justify-center items-center mx-quot rounded-full'>
  <MdCancel className='text-white text-2xl sm:text-3xl lg:text-3xl xl:text-4xl' />
</div>
<div className='text-center space-y-1'>
  <h1 className='font-bold  sm:text-lg lg:text-xl xl:text-2xl'>Please drop a review on your last blind date!</h1>
<p className='text-xs sm:text-sm xl:text-base sm:max-w-sm lg:max-w-md xl:max-w-lg'>Try to be as expressive as possible but keep it within 150 words in length. Your review will guide your handler. </p>
</div>
{/* form */}
<div className='w-full'>
  <textarea
          value={message}
          placeholder='Input review here'
          className='placeholder:text-xs lg:placeholder:text-sm py-2 px-3 rounded-md bg-babygrey bg-opacity-50 w-full  outline-none resize-none overflow-y-auto text-xs md:text-sm xl:text-base'
          onChange={(e) => setMessage(e.target.value)}
           onInput={handleDescriptionChange}
            cols={20}
                            rows={10}
            maxLength={maxCharCount}
        />
   <div
                          className={`text-xs mt-1 px-4 ${
                            charCount === maxCharCount
                              ? 'text-red-500'
                              : 'text-gray-500'
                          }`}
                        >
                          {charCount}/{maxCharCount} characters
                        </div>
</div>
    
<button disabled={isLoading} onClick={()=>handleReview()} className='bg-primary-bg px-4  md:px-6 py-1 shadow-md  rounded-md text-white lg:text-lg xl:py-2'>
 {isLoading ? "Sending..." :"Send Review"}
</button>
</div>
        </div>
      }
      {/* meeting video */}
      {openFrame && (<div style={{zIndex:70}} className="fixed top-0 bottom-0  left-0 right-0 bg-black bg-opacity-70 flex justify-center items-center  ">
       <div className='relative w-full h-full '>
           <Iframe
          url={meetLink}
          width="100%"
          height="100%"
          className="w-full md:h-full  "
          // allowFullScreen={true}
          allow="camera; microphone; fullscreen; autoplay; encrypted-media"
        />
        <h1 className='animate-pulse text-sm lg:text-base font-bold text-white absolute bottom-24 left-2 md:bottom-2 w-max '>Meeting will end by {endTime}  </h1>
       <div  onClick={()=>handleTerminate()} className='absolute bottom-24 md:bottom-2 right-2 w-max   shadow shadow-white  flex items-center gap-2 bg-red-500 text-white px-3 lg:px-5 rounded-md  py-2  cursor-pointer '>
          <h1 className=' text-sm lg:text-base'>End Meeting</h1>
           <FaVideoSlash className='lg:text-xl' />
        </div>
       
       
       </div>
       

        </div>)}
      {/* next */}
     { latestMeeting && <div className="bg-[#017EFA] px-2 py-3 space-y-2 lg:flex lg:items-center lg:justify-between lg:space-y-0 lg:gap-3 w-full overflow-x-hidden lg:px-4">
        <div className='space-y-2 lg:flex lg:items-center lg:space-y-0 lg:gap-3'>
<h1 className="text-center lg:text-left text-white flex-shrink-0">Next meeting scheduled for: </h1>
          <p className="text-center bg-white px-1 sm:px-2 md:px-3 py-2 rounded-sm bg-opacity-80 text-primary-bg font-bold lg:text-left flex-shrink-0">  { moment.utc(latestMeeting?.user_match_meeting_time).format('Do MMMM YYYY, hh:mma')}</p>
        </div>
  
          <button
          onClick={()=>handleJoin()}
            className="bg-primary-bg px-2 md:px-3 lg:px-4 xl:px-5 py-1 md:py-2 flex-shrink-0 text-white rounded-md font-bold text-sm md:text-base  flex justify-center items-center mx-auto lg:mx-0"
          >
            Join Meeting
          </button>
      </div>}

      {/* review */}
    
      
      {/* mother */}
      <div className="space-y-6 lg:space-y-0 lg:grid lg:grid-cols-2 xl:grid-cols-3   w-full h-full gap-6">
        {/* calender */}
        <div className="h-full w-full bg-white shadow-md rounded-md lg:col-span-1 ">
          <Calender setModal={setModal}  userData={userData} setSelectedDate={setSelectedDate}  />
        </div>
        {/* requests */}
        <div className="w-full h-full bg-white shadow-md rounded-md lg:col-span-1  xl:col-span-2">
          <Requests refetch={refetch} />
        </div>
      </div>
      {/* upcoming */}
      <div className="w-full bg-white shadow-md rounded-md">
        <UpcomingEvents setModal={setModal} loading={loading} userData={userData} setSelectedDate={setSelectedDate} />
      </div>
    </main>
  );
}

export default Datefeature;
