import {
  Chat,
  ChevronLeftOutlined,
  ChevronRightOutlined,
  ContactMail,
  List,
  PersonAddAlt,
  Group,
  Add,
  VideoCameraFront,
} from "@mui/icons-material";
import { Fragment, useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Notifications from "src/components/Notification/notifications";
import { SideBarItem } from "src/components/Sidebar/UserDashSideBar";
import { useHandler } from "src/components/auth/hooks/HandlerProvider";
import { useUpdateUserLoginStatus } from "src/hooks/data/mutations";
import { useUser } from "src/components/auth/hooks/UserProvider";
import {
  SideBarItemObj,
  SideBarItemObjAdmin,
  SideBarItemObjHandler,
} from "src/data/sidebar_item";
import Tippy from "@tippyjs/react";
import { Menu, Transition } from "@headlessui/react";
import { SideBarContext } from "src/context/SidebarProvider";
import ASSETS from "src/asset";

interface Page {
  icon: String;
  text: String;
  iconSm: String;
  textSm: String;
  page: String;
  title: String;
}

type Dash = Page[];
const Logo =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/logo.svg";
const Vector =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/vector.svg";
const Question =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/question.svg";
const Bell =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/bell.svg";
const LogoIconSm =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/logout_sm.svg";
const DashboardLayout = () => {
  const location = useLocation();
  const { user, logoutUser } = useUser();
  const { handler, logoutHandler } = useHandler();
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [showSm, setShowSm] = useState(false);
  const [pageInfo, setPageInfo] = useState<Page | null>(null);
  const { isNav } = useContext(SideBarContext);
  const [showNotifications, setShowNotifications] = useState(false);
  const [navlinks, setNavlinks] = useState<Dash | []>([]);
  const [updateUserLoginStatus] = useUpdateUserLoginStatus();

  useEffect(() => {
    setPageInfo(navlinks?.filter((i) => i?.page === location?.pathname)?.[0]);
    setShowSm(false);
    setShowNotifications(false);
  }, [location, navlinks]);

  const handleShowNotification = () => {
    setShowNotifications(!showNotifications);
  };

  const openMenus = () => {
    setShowSm(true);
    setShowNotifications(false);
    setShowNotifications(false);
  };

  const mainpath = window.location.pathname;

  const handleOnlineStatus = async () => {
    await updateUserLoginStatus({
      variables: {
        userId: Number(user?.id),
        isAvailable: false,
        isOnline: false,
        //  last_online: new Date()
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    if (user && user?.__typename?.toLowerCase?.() === "user") {
      return setNavlinks(SideBarItemObj);
    }
    if (handler && handler?.handler_type === "HANDLER") {
      return setNavlinks(SideBarItemObjHandler);
    }
    if (handler?.handler_type === "ADMIN") {
      setNavlinks(SideBarItemObjAdmin);
    }
  }, [user, handler]);
  return (
    <div className="w-full h-full">
      <div className="flex w-full h-screen overflow-hidden bg-white">
        <div
          className={` hidden md:block ${
            show ? "w-72" : "w-28"
          } bg-[#081A51] relative transition-all flex-shrink-0 h-full overflow-hidden `}
        >
          <button
            onClick={() => setShow((val) => !val)}
            className="rounded-lg z-50 shadow-lg bg-white opacity-100 hover:bg-gray-50 cursor-pointer absolute -right-2.5"
          >
            {show ? (
              <ChevronLeftOutlined className="shadow-lg" />
            ) : (
              <ChevronRightOutlined className="shadow-lg" />
            )}
          </button>
          <div className="flex flex-col h-full justify-between overflow-hidden">
            <div className="w-full p-4 gap-3 flex items-center justify-center relative">
              <img src={Vector} className="w-8 h-8" alt="vector" />
              {show && (
                <h2
                  className={`text-white canela text-3xl whitespace-nowrap overflow-hidden transition-all `}
                >
                  Nirvanae
                </h2>
              )}
            </div>
            <div className="h-full py-2 pt-5 px-4 gap-3 overflow-y-auto flex flex-col overflow-hidden">
              {navlinks.map((item, i) => (
                <div key={i}>
                  <SideBarItem
                    icon={item.icon}
                    text={item.text}
                    iconSm={item.iconSm}
                    key={i}
                    textSm={item.textSm}
                    active={pageInfo?.page === item?.page}
                    onClick={() => {
                      setPageInfo(item);
                      navigate(item.page);
                    }}
                    show={show}
                  />
                </div>
              ))}
              {/* {show && 
              <div className="w-full rounded-lg bg-white self-center mt-8 flex flex-col justify-between">
                  <div className="flex justify-between">
                    <img src={Pro2} className="h-8"/>
                    <img src={Pro1} className="h-8"/>
                  </div>
                  <div className="px-2 my-2">
                    <p className="text-[#081A51] font-bold text-sm text-center">Get 50% discount on your subscription</p>
                    <p className="text-[#081A51] text-sm text-center mt-1">Subscribe or renew your membership plan between now and the 31st June to enjoy this offer </p>
                    <div className="w-24 h-10 bg-[#017EFA] flex justify-center items-center m-auto rounded-lg mt-4 cursor-pointer"><p className="text-white font-semibold text-xs ">Get Started</p></div>
                  </div>
                  <div className="flex justify-between">
                    <img src={Pro3} className="h-8"/>
                    <img src={Pro4} className="h-8"/>
                  </div>
              </div>
              } */}
            </div>
            <div className="p-4 flex justify-center items-center">
              {show ? (
                <div
                  className={`bg-[#1B2B65] w-full rounded-lg flex flex-col items-center relative px-4 py-6 transition-all group`}
                >
                  <img
                    src={Question}
                    className="h-16 absolute -top-6"
                    alt="question"
                  />
                  <div
                    className={`flex justify-between gap-2 items-center flex-col overflow-hidden transition-all `}
                  >
                    <h4 className={`text-sm text-white`}>Done for the day?</h4>
                    <button
                      className={`text-xs px-6 py-1  text-white h-10 bg-[#017EFA] rounded-lg gap-2 flex justify-center items-center cursor-pointer `}
                      onClick={() => {
                        localStorage.clear();
                        logoutUser?.();
                        logoutHandler?.();
                        handleOnlineStatus();
                        if (user) {
                          return navigate("/login");
                        }
                        if (handler) {
                          return navigate("/admin");
                        }
                      }}
                    >
                      <img
                        src={LogoIconSm}
                        className="h-6 cursor-pointer"
                        alt="logo"
                      />
                      <p className="font-extrabold text-white">Logout</p>
                    </button>
                  </div>
                </div>
              ) : (
                <img
                  onClick={() => {
                    localStorage.clear();
                    logoutUser?.();
                    logoutHandler?.();
                    handleOnlineStatus();
                    if (user) {
                      return navigate("/login");
                    }
                    if (handler) {
                      return navigate("/admin");
                    }
                  }}
                  src={LogoIconSm}
                  className="h-6 w-6 cursor-pointer"
                  alt="img"
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex-grow relative h-full overflow-hidden bg-[#F2F5FE]">
          <div className="w-full h-20 z-50 sticky top-0 left-0 shadow-md bg-white ">
            <div className="flex h-full px-4 sm:px-5  justify-between items-center">
              <h1 className=" text-base flex gap-2 sm:gap-5 items-center lg:text-2xl font-bold">
                {/* <span onClick={openMenus} className="md:hidden">
                  <TableRows
                    fontSize="small"
                    className="sm:hidden"
                    style={{ cursor: "pointer" }}
                  />
                </span> */}
                <span
                  onClick={() => {
                    if (user) {
                      navigate("/dashboard/friend-request");
                    }
                  }}
                  className="md:hidden"
                >
                  <img src={Logo} className="w-40 h-20" />
                </span>
                <span className="hidden md:inline-block">
                  {pageInfo?.title
                    ? pageInfo?.title
                    : mainpath?.includes?.("dashboard/edit")
                    ? "Edit Profile"
                    : mainpath?.includes?.("dashboard/auth-reset-password")
                    ? "Reset Password"
                    : mainpath?.includes?.("dashboard/choose-a-plan")
                    ? "Manage Subscription"
                    : mainpath?.includes?.("match-profile")
                    ? "User Details"
                    : mainpath?.includes?.("request-profile")
                    ? "Request Details"
                    : "Nirvanae"}
                </span>
                {/* <span className="sm:hidden">Nirvanae</span> */}
              </h1>
              <div className="flex sm:gap-8 gap-4 items-center">
                <div onClick={handleShowNotification}>
                  <img
                    src={Bell}
                    alt="notification"
                    className="h-6 w-6 sm:w-8 sm:h-8  cursor-pointer"
                  />
                </div>
                <div
                  className="flex flex-col gap-1 sm:flex-row sm:gap-2 w-max cursor-pointer items-center "
                  onClick={() => {
                    if (user) {
                      navigate("/dashboard/my-profile");
                    }
                  }}
                >
                  {user?.profile_image_url || handler?.profile_image_url ? (
                    <img
                      src={
                        user?.profile_image_url || handler?.profile_image_url
                      }
                      alt="profile"
                      className="h-8 shadow-md rounded-full w-8 object-cover sm:h-10 sm:w-10"
                    />
                  ) : (
                    <Tippy
                      content={
                        user?.first_name || handler?.user_name || "No user"
                      }
                    >
                      <img
                        src={ASSETS?.Avi}
                        alt="profile"
                        className="h-8 shadow-md rounded-full w-8 object-cover sm:h-10 sm:w-10"
                      />
                    </Tippy>
                  )}
                  <p className="text-sm capitalize hidden md:inline-block font-bold">
                    {user?.first_name || handler?.user_name}
                  </p>
                </div>
                {/* <Menu as="div" className="relative w-max">
                  {({ open }) => (
                    <>
                      <Menu.Button as="div" className="w-full">
                      
                      </Menu.Button>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="focus:outline-none absolute right-0 z-50 mt-1 w-40 md:w-80 origin-top-right overflow-hidden rounded-lg border bg-white py-2 shadow-md ring-1 ring-gray-1">
                          <div className=" space-y-2 divide-y divide-gray-300 overflow-hidden">
                            <Menu.Item>
                              <div className={`px-2 pt-2 `}>
                                <div
                                  onClick={() => {
                                    setUserType("user");
                                    setNavlinks(SideBarItemObj);
                                    navigate(SideBarItemObj?.[0]?.page);
                                  }}
                                  className={`flex cursor-pointer justify-between rounded-lg p-2 ${
                                    userType === "user"
                                      ? "bg-gray-200 font-semibold text-blue-600"
                                      : "hover:bg-gray-100"
                                  } items-center`}
                                >
                                  <p className="text-xs md:text-sm capitalize">
                                    Switch to user
                                  </p>
                                  {userType === "user" && (
                                    <CheckCircleOutline fontSize="small" />
                                  )}
                                </div>
                              </div>
                            </Menu.Item>
                            <Menu.Item>
                              <div className={`px-2 pt-2 `}>
                                <div
                                  onClick={() => {
                                    setUserType("handler");
                                    setNavlinks(SideBarItemObjHandler);
                                    navigate(SideBarItemObjHandler?.[0]?.page);
                                  }}
                                  className={`flex cursor-pointer justify-between rounded-lg p-2 ${
                                    userType === "handler"
                                      ? "bg-gray-200 font-semibold text-blue-600"
                                      : "hover:bg-gray-100"
                                  } items-center`}
                                >
                                  <p className="text-xs md:text-sm capitalize">
                                    Switch to handler
                                  </p>
                                  {userType === "handler" && (
                                    <CheckCircleOutline fontSize="small" />
                                  )}
                                </div>
                              </div>
                            </Menu.Item>
                            <Menu.Item>
                              <div className={`px-2 pt-2 `}>
                                <div
                                  onClick={() => {
                                    setUserType("super admin");
                                    setNavlinks(SideBarItemObjAdmin);
                                    navigate(SideBarItemObjAdmin?.[0]?.page);
                                  }}
                                  className={`flex cursor-pointer justify-between rounded-lg p-2 ${
                                    userType === "super admin"
                                      ? "bg-gray-200 font-semibold text-blue-600"
                                      : "hover:bg-gray-100"
                                  } items-center`}
                                >
                                  <p className="text-xs md:text-sm capitalize">
                                    Switch to super admin
                                  </p>
                                  {userType === "super admin" && (
                                    <CheckCircleOutline fontSize="small" />
                                  )}
                                </div>
                              </div>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu> */}
              </div>
            </div>
          </div>

          <Notifications
            visible={showNotifications}
            close={() => setShowNotifications(false)}
            user_id={Number(user?.id)}
          />
          <div className="h-full w-full overflow-y-auto p-4 pb-52 sm:py-7 sm:px-10 sm:pb-40 relative">
            <Outlet />
            {(user || handler) &&
              isNav &&
              (user ? (
                <div className="fixed md:hidden bottom-0 left-0 z-50 w-full h-20 mx-auto">
                  <div className="flex justify-between relative gap-5  bg-[#081A51] items-center px-5 h-full w-full">
                    <div
                      onClick={() => {
                        navigate("/dashboard/handler-chat");
                      }}
                      className={`${
                        mainpath?.includes?.("dashboard/handler-chat")
                          ? "bg-[#6e88d73e]"
                          : ""
                      } flex flex-col gap-1 p-1 rounded-lg items-center text-white w-full`}
                    >
                      <div className="w-8 h-8 bg-[#6e88d73e] text-xl flex-shrink-0 rounded-full flex justify-center items-center">
                        <ContactMail fontSize="inherit" />
                      </div>
                      <p className="text-[8px]">My Handler</p>
                    </div>

                    <Menu as="div" className="w-max">
                      {({ open }) => (
                        <>
                          <Menu.Button as="div" className="w-full">
                            <div
                              className={`flex gap-3 justify-center ${
                                mainpath?.includes?.(
                                  "dashboard/friend-request"
                                ) ||
                                mainpath?.includes?.(
                                  "dashboard/profile-suggestion"
                                )
                                  ? "bg-[#6e88d73e]"
                                  : ""
                              } rounded-lg items-center py-4 px-2.5 text-white w-full`}
                            >
                              <p className="text-xs">Menu</p>
                              <List />
                            </div>
                          </Menu.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="focus:outline-none absolute left-0 z-50 mb-2 w-full origin-bottom-left bottom-full overflow-hidden rounded-lg border bg-[#081A51] shadow-md ring-1 ring-[#081A51]">
                              <div className=" divide-y divide-[#6e88d73e]">
                                <div className="divide-y divide-[#6e88d73e] px-3.5 pt-4">
                                  <Menu.Item>
                                    <div className="py-2">
                                      <div
                                        onClick={() => {
                                          navigate("/dashboard/friend-request");
                                        }}
                                        className={` ${
                                          mainpath?.includes?.(
                                            "dashboard/friend-request"
                                          )
                                            ? "bg-[#6e88d73e]"
                                            : ""
                                        } flex rounded-full p-1.5 px-3 text-lg gap-2 text-white relative items-center`}
                                      >
                                        <PersonAddAlt fontSize="inherit" />
                                        <p className="font-extrabold relative text-white">
                                          Discover
                                        </p>
                                      </div>
                                    </div>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <div className="py-2">
                                      <div
                                        onClick={() => {
                                          navigate(
                                            "/dashboard/profile-suggestion"
                                          );
                                        }}
                                        className={` ${
                                          mainpath?.includes?.(
                                            "dashboard/profile-suggestion"
                                          )
                                            ? "bg-[#6e88d73e]"
                                            : ""
                                        } flex rounded-full p-1.5 px-3 text-lg gap-2 text-white relative items-center`}
                                      >
                                        <ContactMail fontSize="inherit" />
                                        <p className="font-extrabold relative text-white">
                                          Handler's Suggestion
                                          <p className="text-[9px] ml-2 text-orange-600 absolute left-full -top-1 ">
                                            premium
                                          </p>
                                        </p>
                                      </div>
                                    </div>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <div className="py-2">
                                      <div
                                        onClick={() => {
                                          navigate("/dashboard/blind-date");
                                        }}
                                        className={` ${
                                          mainpath?.includes?.(
                                            "dashboard/blind-date"
                                          )
                                            ? "bg-[#6e88d73e]"
                                            : ""
                                        } flex rounded-full p-1.5 px-3 text-lg gap-2 text-white relative items-center`}
                                      >
                                        <img
                                          src={ASSETS.blindicon}
                                          alt=""
                                          className="w-5 h-5"
                                        />

                                        <div className="flex items-center gap-2 ">
                                          <p className="font-extrabold relative text-white">
                                            Blind Dates
                                          </p>
                                          <img
                                            src={"/images/new.png"}
                                            alt="profile_picture"
                                            width={1000}
                                            height={1000}
                                            className="w-10    "
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Menu.Item>
                                  <Menu.Item>
                                    <div className="py-2">
                                      <div
                                        onClick={() => {
                                          navigate("/dashboard/choose-a-plan");
                                        }}
                                        className={` ${
                                          mainpath?.includes?.(
                                            "/dashboard/choose-a-plan"
                                          )
                                            ? "bg-[#6e88d73e]"
                                            : ""
                                        } flex rounded-full p-1.5 px-3 text-lg gap-2 text-white relative items-center`}
                                      >
                                        <img
                                          src={ASSETS.sub}
                                          alt=""
                                          className="w-5 h-5"
                                        />

                                        <div className="flex items-center gap-2 ">
                                          <p className="font-extrabold relative text-white">
                                            Subscriptions
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Menu.Item>
                                </div>
                                <div className="py-4 px-5">
                                  <div
                                    onClick={() => {
                                      localStorage.clear();
                                      logoutUser?.();
                                      logoutHandler?.();
                                      handleOnlineStatus();
                                      if (user) {
                                        return navigate("/login");
                                      }
                                      if (handler) {
                                        return navigate("/admin");
                                      }
                                    }}
                                    className="flex justify-center border border-[#6e88d73e] w-full rounded-full py-2 gap-2 items-center"
                                  >
                                    <img
                                      src={LogoIconSm}
                                      className="h-6 cursor-pointer"
                                      alt="logo"
                                    />
                                    <p className="font-extrabold text-white">
                                      Logout
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>

                    <div
                      onClick={() => {
                        navigate("/dashboard/messages");
                      }}
                      className={`${
                        mainpath?.includes?.("dashboard/messages")
                          ? "bg-[#6e88d73e]"
                          : ""
                      } flex flex-col gap-1 p-1 rounded-lg items-center text-white w-full`}
                    >
                      <div className="w-8 h-8  bg-[#6e88d73e] text-xl flex-shrink-0 rounded-full flex justify-center items-center">
                        <Chat fontSize="inherit" />
                      </div>
                      <p className="text-[8px]">My Messages</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="fixed md:hidden bottom-0 left-0 z-50 w-full h-20 mx-auto">
                  <div className="flex justify-between relative gap-5 bg-[#081A51] items-center px-5 h-full w-full">
                    <div
                      onClick={() => {
                        navigate("/dashboard/handler");
                      }}
                      className={`${
                        mainpath?.includes("dashboard/handler") &&
                        !mainpath?.includes("chat") &&
                        !mainpath?.includes("add-user") &&
                        !mainpath?.includes("blind-dates")
                          ? "bg-[#6e88d73e]"
                          : ""
                      } flex flex-col gap-1 p-1 rounded-lg items-center text-white w-full`}
                    >
                      <div className="w-8 h-8 bg-[#6e88d73e] text-xl flex-shrink-0 rounded-full flex justify-center items-center">
                        <Group fontSize="inherit" />
                      </div>
                      <p className="text-[8px]">Users</p>
                    </div>

                    <Menu as="div" className="w-max">
                      {({ open }) => (
                        <>
                          <Menu.Button as="div" className="w-full">
                            <div
                              className={`flex gap-3 justify-center ${
                                mainpath?.includes?.("add-user")
                                  ? "bg-[#6e88d73e]"
                                  : ""
                              } rounded-lg items-center py-4 px-2.5 text-white w-full`}
                            >
                              <p className="text-xs">Menu</p>
                              <List />
                            </div>
                          </Menu.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="focus:outline-none absolute left-0 z-50 mb-2 w-full origin-bottom-left bottom-full overflow-hidden rounded-lg border bg-[#081A51] shadow-md ring-1 ring-[#081A51]">
                              <div className="space-y-3 divide-y divide-[#6e88d73e]">
                                <div className="divide-y divide-[#6e88d73e] px-3.5 pt-4">
                                  <Menu.Item>
                                    <div className="py-2">
                                      <div
                                        onClick={() => {
                                          navigate(
                                            "/dashboard/handler/add-user"
                                          );
                                        }}
                                        className={` ${
                                          mainpath?.includes?.("add-user")
                                            ? "bg-[#6e88d73e]"
                                            : ""
                                        } flex rounded-full p-1.5 px-3 text-lg gap-2 text-white relative items-center`}
                                      >
                                        <Add fontSize="inherit" />
                                        <p className="font-extrabold relative text-white">
                                          Add User
                                        </p>
                                      </div>
                                    </div>
                                  </Menu.Item>
                                  {/* <Menu.Item>
                                    <div className="py-2">
                                      <div
                                        onClick={() => {
                                          navigate(
                                            "/dashboard/handler/blind-dates"
                                          );
                                        }}
                                        className={` ${
                                          mainpath?.includes?.("blind-dates")
                                            ? "bg-[#6e88d73e]"
                                            : ""
                                        } flex rounded-full p-1.5 px-3 text-lg gap-2 text-white relative items-center`}
                                      >
                                        <VideoCameraFront fontSize="inherit" />
                                        <p className="font-extrabold relative text-white">
                                          Blind Dates
                                        </p>
                                      </div>
                                    </div>
                                  </Menu.Item> */}
                                </div>
                                <div className="py-4 px-5">
                                  <div
                                    onClick={() => {
                                      localStorage.clear();
                                      logoutUser?.();
                                      logoutHandler?.();
                                      handleOnlineStatus();
                                      if (user) {
                                        return navigate("/login");
                                      }
                                      if (handler) {
                                        return navigate("/admin");
                                      }
                                    }}
                                    className="flex justify-center border border-[#6e88d73e] w-full rounded-full py-2 gap-2 items-center"
                                  >
                                    <img
                                      src={LogoIconSm}
                                      className="h-6 cursor-pointer"
                                      alt="logo"
                                    />
                                    <p className="font-extrabold text-white">
                                      Logout
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>

                    <div
                      onClick={() => {
                        navigate("/dashboard/handler/chat");
                      }}
                      className={`${
                        mainpath?.includes?.("dashboard/handler/chat")
                          ? "bg-[#6e88d73e]"
                          : ""
                      } flex flex-col gap-1 p-1 rounded-lg items-center text-white w-full`}
                    >
                      <div className="w-8 h-8  bg-[#6e88d73e] text-xl flex-shrink-0 rounded-full flex justify-center items-center">
                        <Chat fontSize="inherit" />
                      </div>
                      <p className="text-[8px]">Messages</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {showSm && (
          <div
            onClick={() => {
              setShowSm(false);
            }}
            className={`lg:hidden bg-[#000107] transition-all z-50 absolute bg-opacity-50
          h-full overflow-hidden w-full flex flex-row-reverse`}
          >
            <p
              className="text-2xl text-white font-semibold cursor-pointer top-[0px] absolute right-[16px] opacity-[99] "
              onClick={() => setShowSm(false)}
            >
              x
            </p>
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="absolute left-0 flex flex-col h-full justify-between overflow-hidden w-[60%] bg-[#081A51]"
            >
              <div className="w-full p-4 gap-3 flex items-center justify-center relative">
                <img src={Vector} className="w-8 h-8" alt="vector" />
                {show && (
                  <h2
                    className={`text-white canela text-3xl whitespace-nowrap overflow-hidden transition-all `}
                  >
                    Nirvanae
                  </h2>
                )}
              </div>
              <div className="h-full py-2 pt-5 px-4 gap-3 overflow-y-auto flex flex-col overflow-hidden">
                {navlinks.map((item, i) => (
                  <div key={i}>
                    <SideBarItem
                      icon={item.icon}
                      text={item.text}
                      iconSm={item.iconSm}
                      key={i}
                      textSm={item.textSm}
                      active={pageInfo?.page === item?.page}
                      onClick={() => {
                        setPageInfo(item);
                        navigate(item.page);
                      }}
                      show={show}
                    />
                  </div>
                ))}
              </div>
              <div className="p-4 flex justify-center items-center">
                {show ? (
                  <div
                    className={`bg-[#1B2B65] w-full rounded-lg flex flex-col items-center relative px-4 py-6 transition-all group`}
                  >
                    <img
                      src={Question}
                      className="h-16 absolute -top-6"
                      alt="question"
                    />
                    <div
                      className={`flex justify-between gap-2 items-center flex-col overflow-hidden transition-all `}
                    >
                      <h4 className={`text-sm text-white`}>
                        Done for the day?
                      </h4>
                      <button
                        onClick={() => {
                          localStorage.clear();
                          logoutUser?.();
                          logoutHandler?.();
                          handleOnlineStatus();
                          if (user) {
                            return navigate("/login");
                          }
                          if (handler) {
                            return navigate("/admin");
                          }
                        }}
                        className={`text-xs px-6 py-1  text-white h-10 bg-[#017EFA] rounded-lg gap-2 flex justify-center items-center cursor-pointer `}
                      >
                        <img
                          src={LogoIconSm}
                          className="h-6 cursor-pointer"
                          alt="logo"
                        />
                        <p className="font-extrabold text-white">Logout</p>
                      </button>
                    </div>
                  </div>
                ) : (
                  <img
                    src={LogoIconSm}
                    onClick={() => {
                      localStorage.clear();
                      logoutUser?.();
                      logoutHandler?.();
                      handleOnlineStatus();
                      if (user) {
                        return navigate("/login");
                      }
                      if (handler) {
                        return navigate("/admin");
                      }
                    }}
                    className="h-6 w-6 cursor-pointer"
                    alt="img"
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardLayout;
