import { gql } from "@apollo/client";

export const VERIFY_EMAIL_ADDRESS = gql`
  query Query($email: String!) {
    verifyEmail(email: $email)
  }
`;

export const VERIFY_PHONE_NUMBER = gql`
  query Query($number: String!) {
    verifyPhone(number: $number)
  }
`;
export const GET_HANDLER_USERS = gql`
  query Query($handlerId: Int!) {
    handlerUsers(handler_id: $handlerId) {
      id
      about_me
      email
      email_is_verified
      first_name
      gender
      genotype
      last_online
      is_available
      last_name
      # interests
      location_city
      location_country
      location_state
      first_timer
      media_ids
      plan
      handler {
        user_name
        id
        profile_image_url
      }
      media {
        id
        url
        title
        description
        is_avatar
        deleted
      }
      subscription {
        id
        cost
        created_at
        deleted
        deleted_at
        name
        monthly_matches
        priority
        updated_at
      }
      occupation
      preferred_age
      preferred_country
      preferred_gender
      preferred_genotype
      preferred_religion
      preferred_status
      profile_image_url
      qualification
      religion
      updated_at
      age
      alais
      body_type
      country_code
      created_at
      deleted
      hobbies
      is_online
      userReport {
        id
        message
        subject
        created_at
      }
      userHandlerRating {
        created_at
        id
        rating
        updated_at
      }
      handler {
        user_name
        id
      }
      status
      #user_status
      phone
      password
      origin_state
      origin_country
      ethnicity
    }
  }
`;

export const USER_PENDING_REQUESTS = gql`
  query UserPendingRequests(
    $suggestedBy: String!
    $userPendingRequestsId: Int!
  ) {
    userPendingRequests(
      suggested_by: $suggestedBy
      id: $userPendingRequestsId
    ) {
      created_at
      deleted
      deleted_at
      id
      user_match {
        id
        occupation
        age
        about_me
        last_name
        profile_image_url
        first_name
        last_online
        location_state
        location_country
        gender
        status
      }
      user_match_status
      user_matched {
        id
        occupation
        last_name
        profile_image_url
        first_name
        last_online
        about_me
        age
        location_state
        location_country
        gender
        status
      }
      user_matched_status
      updated_at
      suggested_by
    }
  }
`;

export const USER_COMPLETED_MATCHES = gql`
  query UserMatches($userMatchesId: Int!) {
    userMatches(id: $userMatchesId) {
      created_at
      deleted
      deleted_at
      id
      user_match_status
      user_matched {
        id
        occupation
        last_name
        profile_image_url
        first_name
        last_online
      }
      user_matched_status
      updated_at
    }
  }
`;

export const ADMIN_DASHBOARD = gql`
  query AdminDashboard($adminId: Int!) {
    adminDashboard(admin_id: $adminId) {
      all_blacklisted_users
      all_handlers_count
      all_inactive_users
      all_users_count
      handlers {
        id
        user_name
      }
    }
  }
`;
export const HANDLER_USERS = gql`
  query HandlerUsers($handlerId: Int!) {
    handlerUsers(handler_id: $handlerId) {
      first_name
      last_name
      email
      created_at
      phone
    }
  }
`;

export const ADMIN_HANDLER_CONVOS = gql`
  query AdminhandlerConversations($handlerId: Int!) {
    adminhandlerConversations(handler_id: $handlerId) {
      id
      handler_messages {
        body
        created_at
        id
        message_sender
        status
        subject
        metadata
        updated_at
      }
      updated_at
      created_at
      unreadMessageCount
      lastMessageSentAt
      user {
        id
        last_name
        first_name
        profile_image_url
        is_online
      }
    }
  }
`;
export const ADMIN_HANDLER_SUGGESTIONS = gql`
  query HandlerSuggestions($handlerId: Int!) {
    handlerSuggestions(handler_id: $handlerId) {
      id
      user_match {
        id
        user_matched {
          id
          first_name
          occupation
          profile_image_url
        }
        user_match_status
        user_matched_status
        created_at
      }
    }
  }
`;
// export const GET_BLIND_DATE_REQUESTS = gql`
// query GetBlindDates($payload: MultiBlindDatePayload) {
//   getBlindDates(payload: $payload) {
//     id
//     matched_accept
//     matched_cancel
//     created_at
//     match_accept
//     match_cancel
//     match_reject
//     match_upcoming
//     user_match_meeting_time
//     user_matched_meeting_time
//     user_match {
//       user_matched {
//         age
//         alais
//         gender
//         occupation
//         origin_country
//         origin_state
//         location_city
//         location_country
//         location_state
//       }
//     }
//   }
// }`

export const GET_UPCOMING_EVENTS = gql`
  query UpcomingEvents($getBlindDateUpcomingMatchesId: Int!) {
    getBlindDateUpcomingMatches(id: $getBlindDateUpcomingMatchesId) {
      id
      match_user_id
      matched_user_id
      user_match_theme
      user_match_meeting_time
      user_matched_timezone
      user_match_timezone
      meeting_link
      meeting_id
      user_match {
        user_matched {
          age
          alais
          gender
          location_city
          location_country
          location_state
          origin_country
          occupation
          origin_state
        }
      }
    }
  }
`;

export const CHECK_FIRST_TIMER = gql`
  query ExampleQuery($checkFirstTimerId: Int!) {
    checkFirstTimer(id: $checkFirstTimerId) {
      first_timer
      id
    }
  }
`;
export const GET_DATE_REQUESTS = gql`
  query Query($getBlindDateRequestMatchesId: Int!) {
    getBlindDateRequestMatches(id: $getBlindDateRequestMatchesId) {
      id
      match_user_id
      matched_user_id
      match_accept
      match_cancel
      match_reject
      matched_accept
      matched_cancel
      matched_reject
      user_match_meeting_time
      user_matched_meeting_time
      user_match {
        user_matched {
          alais
          age
          gender
          location_city
          location_country
          location_state
          occupation
          origin_country
          origin_state
        }
      }
    }
  }
`;
