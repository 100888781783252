import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import ASSETS from "src/asset";
import Cancel from "src/assets/cancel.svg";
import Mark from "src/assets/mark.svg";
import SingleButton from "src/components/SingleButton";
import { useCurrency } from "src/context/AmountProvider";
import { GENERICPLANSNG, GENERICPLANSUSD } from "src/helpers/constants";

const MainVerificationSuccess = () => {
  const location = useLocation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    waitForAnimate: false,
    cssEase: "linear",
    swipeToSlide: true,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const plans = ["Monthly", "Quarterly", "Bi-annually", "Annually"];

  const { premiumMonth, premiumBi, premiumYear, premiumQuater, country } =
    useCurrency();
  const [currency, setCurrency] = useState(
    country?.trim?.() === "nigeria" ? "₦" : "$"
  );
  const [amount, setAmount] = useState(
    country?.trim?.() === "nigeria" ? "200000" : "200"
  );

  const navigate = useNavigate();

  //@ts-ignore
  const payload = location?.state?.payload || null;

  const [singlePlan, setSinglePlan] = useState("annually");

  const handleChoosePlan = (passedplan) => {
    setSinglePlan(passedplan?.plan);
    setAmount(passedplan?.amount);
  };

  const majorPlans =
    country?.trim?.() === "nigeria" ? GENERICPLANSNG : GENERICPLANSUSD;

  useEffect(() => {
    if (!payload) {
      navigate("/pre-verify");
    }
  }, [payload]);

  useEffect(() => {
    if (country?.trim?.() === "nigeria") {
      setCurrency("₦");
      setAmount("200000");
    } else {
      setCurrency("$");
      setAmount("200");
    }
  }, [country]);

  return (
    <div className="bg-white overflow-x-hidden w-full h-screen">
      <div className=" h-full flex-col md:flex-row  overflow-x-hidden flex p-4 gap-5 sm:p-6 lg:p-10 w-full">
        {/* text */}
        <div className="space-y-4 flex flex-col justify-center py-4 w-full md:w-1/2  xl:w-2/5 flex-shrink-0">
          <img
            onClick={() => {
              navigate("/");
            }}
            src={ASSETS?.Logo}
            className=" w-24 cursor-pointer h-10 sm:h-20"
          />
          <p className="text-[#081A51] playfair text-2xl font-bold sm:text-3xl   md:text-4xl lg:text-5xl xl:text-6xl">
            {`Congratulations ${payload?.first_name} ${payload?.last_name} 🎉!`}
          </p>
          <p className="text-[#223333] jarkata_sans pb-3 text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl font-medium ">
            You are a fit for our matchmaking service .Your thoroughness in
            providing all the necessary information has been very useful and we
            are thrilled to get you onboard. Here’s to our favorite love
            story,🥂 yours.
            <br />
            <br /> Select a membership plan to complete your registration on our
            platform
          </p>

          <div className="w-full py-2 rounded-lg flex  flex-wrap items-center gap-2 md:gap-3 xl:gap-4 justify-between ">
            {majorPlans
              ?.slice?.()
              ?.reverse?.()
              ?.map?.((plan) => (
                <div
                  onClick={() => handleChoosePlan(plan)}
                  className={`${
                    singlePlan === plan?.plan
                      ? "bg-primary-bg px-4 py-2 sm:py-3  h-max  rounded-md text-white text-center cursor-pointer lg:px-6 flex-grow"
                      : "text-primary-bg  rounded-md  text-center bg-[#E8EDFB] px-4 py-2 sm:py-3 cursor-pointer lg:px-6 h-max flex-grow"
                  }  `}
                >
                  <p className="font-bold capitalize text-xs  md:text-sm  xl:text-base">
                    {plan?.plan}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* card */}
        <div className="flex-grow md:w-1/2 xl:w-3/5">
          <div className="slider-container px-5 xl:hidden pb-10">
            <Slider {...settings}>
              {/* premium mobible */}
              <div className="py-3">
                <div className=" max-md:p-2 p-4 rounded-xl bg-[#081A51]">
                  <p className="text-white text-2xl font-semibold">
                    Premium Tier
                  </p>
                  <p className="mt-4 text-white">
                    Ideal for individuals who need to access our advanced
                    features and personalize their dating experience{" "}
                  </p>
                  <div className="flex items-center mt-8">
                    <p className="text-2xl font-semibold text-white mr-1 max-md:text-[11px]">
                      {currency} {Number(amount)?.toLocaleString?.()}
                    </p>
                    <p className="text-white text-xs"> / {singlePlan}</p>
                  </div>
                  <SingleButton
                    className="border mt-10 py-2 rounded-sm bg-[#FFFFFF] cursor-pointer"
                    text="Get Started Now"
                    inlineStyle="text-center text-[#081A51]"
                    onBtnClick={() =>
                      navigate("/get-started/premium", {
                        state: {
                          currency_code: PlanAmountType(
                            singlePlan,
                            premiumMonth,
                            premiumBi,
                            premiumYear,
                            premiumQuater,
                            1
                          ),
                          plan_cost: PlanAmountType(
                            singlePlan,
                            premiumMonth,
                            premiumBi,
                            premiumYear,
                            premiumQuater,
                            0
                          ),
                          plan_duration: singlePlan,
                          payload,
                        },
                      })
                    }
                  />
                  {singlePlan === "annually" && (
                    <div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="mark" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          View profiles in our private matchmaking dating pool,
                          get new suggestions in Discover.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Receive the Matchmaker’s Monthly Digest, a niche
                          publication sent to your mail.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Upon your request, get at least one(1) personalized
                          match from your handler, monthly.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Receive more precise suggestions in Handler’s
                          Suggestions, 50 percent of which are on premium
                          annually.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Receive other suggestions from members on other
                          membership levels to boost your search.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Full handler support.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Access to system scheduled Blind Dates.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Get after match support.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Upon your request, get set up on personalized Blind
                          dates by Handler.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Upon your request, schedule one(1) therapy session
                          with a professional clinical psychologist.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Receive a full report that informs you about you, and
                          the kind of partners you should be looking out for
                          within our private matchmaking community.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Receive a full report on any match you want to make,
                          including match history, strike history, blacklist
                          report- and any other thing you’ll like to know about
                          a potential match, within our knowledge.
                        </p>
                      </div>
                    </div>
                  )}
                  {singlePlan === "bi-annually" && (
                    <div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="mark" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          View profiles in our private matchmaking dating pool,
                          get new random suggestions in Discover .
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Receive the Matchmaker’s Monthly Digest, a niche
                          publication sent to your mail.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Upon your request, get at least one(1) personalized
                          match from your handler, monthly.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Receive more precise suggestions in Handler’s
                          Suggestions, 50 percent of which are on premium
                          annually.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Receive other suggestions from members on other
                          membership levels to boost your search.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Full handler support.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Access to system scheduled Blind Dates.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Get after match support.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Upon your request, get set up on personalized Blind
                          dates by Handler.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          No feedback on potential matches.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          No scheduled therapy sessions with psychologist.
                        </p>
                      </div>

                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          No access to therapy reports.
                        </p>
                      </div>
                    </div>
                  )}
                  {singlePlan === "quarterly" && (
                    <div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="mark" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          View profiles in our private matchmaking dating pool,
                          get new random suggestions in Discover.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Receive the Matchmaker’s Monthly Digest, a niche
                          publication sent to your mail.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Upon your request, get one(1) personalized match from
                          your handler, monthly.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Receive more precise suggestions in Handler’s
                          Suggestions, 75 percent of which are other Premium
                          Quarterly subscribers.
                        </p>
                      </div>

                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Full handler support.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Access to system scheduled Blind Dates.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Get after match support.
                        </p>
                      </div>

                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          No access to personalized Blind dates by Handler.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          No scheduled therapy sessions with psychologist.
                        </p>
                      </div>

                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          No access to therapy reports.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          No feedback on potential matches.
                        </p>
                      </div>
                    </div>
                  )}
                  {singlePlan === "monthly" && (
                    <div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="mark" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          View profiles in our private matchmaking dating pool,
                          get new random suggestions in Discover.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Receive the Matchmaker’s Monthly Digest, a niche
                          publication sent to your mail.
                        </p>
                      </div>

                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Receive more precise suggestions in Handler’s
                          Suggestions, 90 percent of which are other monthly
                          subscribers.
                        </p>
                      </div>

                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Full handler support.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Access to system scheduled Blind Dates.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          Get after match support.
                        </p>
                      </div>

                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          No access to Blind dates by Handler.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          No scheduled therapy sessions with psychologist.
                        </p>
                      </div>

                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          No access to therapy reports.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className="text-white text-sm max-md:text-[11px]">
                          No feedback on potential matches.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* free  mobile*/}
              <div className="p-3">
                <div className=" max-md:p-2 p-4 rounded-xl border border-[#98A2B3]">
                  <p className="text-[#081A51] text-2xl font-semibold">
                    Free Tier
                  </p>
                  <p className="mt-4 text-[#223333]">
                    Ideal for individuals who need a quick access to basic
                    features with an idea of exploring our platofrm
                  </p>
                  <div className="flex items-center mt-8">
                    <p className="text-2xl font-semibold text-[#081A51] mr-1 max-md:text-[11px]">
                      {currency} 0
                    </p>
                    <p className="text-[#223333] text-xs"> / {singlePlan}</p>
                  </div>
                  <SingleButton
                    className="border border-[#081A51] mt-10 py-2 rounded-sm cursor-pointer"
                    text="Get Started Now"
                    inlineStyle="text-center text-[#081A51]"
                    //@ts-ignore
                    onBtnClick={() => {
                      navigate("/get-started/basic", {
                        state: {
                          payload,
                        },
                      });
                    }}
                  />
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="mark" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      View profiles in our private matchmaking dating pool, get
                      new random suggestions in Discover.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="mark" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      Receive the Matchmaker’s Monthly Digest, a niche
                      publication sent to your mail.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      No access to matches with more precision in Handler’s
                      suggestions.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      No personalized matching by handler
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      No matching with private subscribers.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      Limited handler support.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      No access to system scheduled Blind Dates.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      No access to personalized Blind dates by Handler.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      No after match support.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      No scheduled therapy sessions.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      No access to therapy reports.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-[#223333] text-sm max-md:text-[11px]">
                      No feedback on potential matches.
                    </p>
                  </div>
                  {/* <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-[#223333] text-sm max-md:text-[11px]">
                    Handler unlocked for all your questions, complaints and for
                    direct referrals
                  </p>
                </div> */}
                </div>
              </div>
            </Slider>
          </div>
          <div className="p-4 lg:grid hidden grid-cols-2 gap-5">
            <div className=" max-md:p-2 p-4 rounded-xl bg-[#081A51]">
              <p className="text-white text-2xl font-semibold">Premium Tier</p>
              <p className="mt-4 text-white">
                Ideal for individuals who need to access our advanced features
                and personalize their dating experience{" "}
              </p>
              <div className="flex items-center mt-8">
                <p className="text-2xl font-semibold text-white mr-1 max-md:text-[11px]">
                  {currency} {Number(amount)?.toLocaleString?.()}
                </p>
                <p className="text-white text-xs"> / {singlePlan}</p>
              </div>
              <SingleButton
                className="border mt-10 py-2 rounded-sm bg-[#FFFFFF] cursor-pointer"
                text="Get Started Now"
                inlineStyle="text-center text-[#081A51]"
                onBtnClick={() => {
                  navigate("/get-started/premium", {
                    state: {
                      currency_code: PlanAmountType(
                        singlePlan,
                        premiumMonth,
                        premiumBi,
                        premiumYear,
                        premiumQuater,
                        1
                      ),
                      plan_cost: PlanAmountType(
                        singlePlan,
                        premiumMonth,
                        premiumBi,
                        premiumYear,
                        premiumQuater,
                        0
                      ),
                      plan_duration: singlePlan,
                      payload,
                    },
                  });
                }}
              />
              {singlePlan === "annually" && (
                <div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="mark" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      View profiles in our private matchmaking dating pool, get
                      new suggestions in Discover.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Receive the Matchmaker’s Monthly Digest, a niche
                      publication sent to your mail.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Upon your request, get at least one(1) personalized match
                      from your handler, monthly.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Receive more precise suggestions in Handler’s
                      Suggestions, 50 percent of which are on premium annually.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Receive other suggestions from members on other membership
                      levels to boost your search.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Full handler support.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Access to system scheduled Blind Dates.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Get after match support.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Upon your request, get set up on personalized Blind dates
                      by Handler.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Upon your request, schedule one(1) therapy session with a
                      professional clinical psychologist.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Receive a full report that informs you about you, and the
                      kind of partners you should be looking out for within our
                      private matchmaking community.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Receive a full report on any match you want to make,
                      including match history, strike history, blacklist report-
                      and any other thing you’ll like to know about a potential
                      match, within our knowledge.
                    </p>
                  </div>
                </div>
              )}
              {singlePlan === "bi-annually" && (
                <div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="mark" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      View profiles in our private matchmaking dating pool, get
                      new random suggestions in Discover.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Receive the Matchmaker’s Monthly Digest, a niche
                      publication sent to your mail.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Upon your request, get at least one(1) personalized match
                      from your handler, monthly.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Receive more precise suggestions in Handler’s
                      Suggestions, 50 percent of which are on premium annually.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Receive other suggestions from members on other membership
                      levels to boost your search.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Full handler support.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Access to system scheduled Blind Dates.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Get after match support.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Upon your request, get set up on personalized Blind dates
                      by Handler.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      No feedback on potential matches.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      No scheduled therapy sessions with psychologist.
                    </p>
                  </div>

                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      No access to therapy reports.
                    </p>
                  </div>
                </div>
              )}
              {singlePlan === "quarterly" && (
                <div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="mark" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      View profiles in our private matchmaking dating pool, get
                      new random suggestions in Discover.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Receive the Matchmaker’s Monthly Digest, a niche
                      publication sent to your mail.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Upon your request, get one(1) personalized match from your
                      handler, monthly.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Receive more precise suggestions in Handler’s
                      Suggestions, 75 percent of which are other Premium
                      Quarterly subscribers.
                    </p>
                  </div>

                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Full handler support.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Access to system scheduled Blind Dates.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Get after match support.
                    </p>
                  </div>

                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      No access to personalized Blind dates by Handler.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      No scheduled therapy sessions with psychologist.
                    </p>
                  </div>

                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      No access to therapy reports.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      No feedback on potential matches.
                    </p>
                  </div>
                </div>
              )}
              {singlePlan === "monthly" && (
                <div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="mark" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      View profiles in our private matchmaking dating pool, get
                      new random suggestions in Discover.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Receive the Matchmaker’s Monthly Digest, a niche
                      publication sent to your mail.
                    </p>
                  </div>

                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Receive more precise suggestions in Handler’s
                      Suggestions, 90 percent of which are other monthly
                      subscribers.
                    </p>
                  </div>

                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Full handler support.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Access to system scheduled Blind Dates.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Mark} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      Get after match support.
                    </p>
                  </div>

                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      No access to Blind dates by Handler.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      No scheduled therapy sessions with psychologist.
                    </p>
                  </div>

                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      No access to therapy reports.
                    </p>
                  </div>
                  <div className="flex mt-10">
                    <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                    <p className="text-white text-sm max-md:text-[11px]">
                      No feedback on potential matches.
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className=" max-md:p-2 p-4 rounded-xl border border-[#98A2B3]">
              <p className="text-[#081A51] text-2xl font-semibold">Free Tier</p>
              <p className="mt-4 text-[#223333]">
                Ideal for individuals who need a quick access to basic features
                with an idea of exploring our platofrm
              </p>
              <div className="flex items-center mt-8">
                <p className="text-2xl font-semibold text-[#081A51] mr-1 max-md:text-[11px]">
                  {currency} 0
                </p>
                <p className="text-[#223333] text-xs"> / {singlePlan}</p>
              </div>
              <SingleButton
                className="border border-[#081A51] mt-10 py-2 rounded-sm cursor-pointer"
                text="Get Started Now"
                inlineStyle="text-center text-[#081A51]"
                onBtnClick={() =>
                  navigate("/get-started/basic", {
                    state: {
                      payload,
                    },
                  })
                }
              />
              <div className="flex mt-10">
                <img src={Mark} className="h-6 mr-4" alt="mark" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  View profiles in our private matchmaking dating pool, get new
                  random suggestions in Discover.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Mark} className="h-6 mr-4" alt="mark" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  Receive the Matchmaker’s Monthly Digest, a niche publication
                  sent to your mail.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No access to matches with more precision in Handler’s
                  suggestions.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No personalized matching by handler.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No matching with private subscribers.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  Limited handler support.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No access to system scheduled Blind Dates.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No access to personalized Blind dates by Handler.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No after match support.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No scheduled therapy sessions.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No access to therapy reports.
                </p>
              </div>
              <div className="flex mt-10">
                <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                <p className="text-[#223333] text-sm max-md:text-[11px]">
                  No feedback on potential matches.
                </p>
              </div>
              {/* <div className="flex mt-10">
              <img src={Cancel} className="h-6 mr-4" alt="cancel" />
              <p className="text-[#223333] text-sm max-md:text-[11px]">
                Handler unlocked for all your questions, complaints and for
                direct referrals
              </p>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//  <h1 className="text-xs text-center italic text-red-500">Note: Our algorithm is designed to suggest more members within your chosen membership tier.</h1>
export default MainVerificationSuccess;

const PlanAmountType = (
  plan: string,
  month: string,
  bi_annual: string,
  annual: string,
  quarter: string,
  index: number
) => {
  switch (plan) {
    case "bi-annually":
      return `${bi_annual.split("-")[index]}`;

    case "annually":
      return `${annual.split("-")[index]}`;

    case "monthly":
      return `${month.split("-")[index]}`;

    case "quarterly":
      return `${quarter.split("-")[index]}`;

    default:
      return ``;
  }
};
