import ASSETS from "src/asset";
const Message =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/message_icon.svg";
const MessageSm =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/message_icon_sm.svg";
const MyProfile =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/my_profile.svg";
const Profile =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/profile.svg";
const ProfileSm =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/profile_sm.svg";
const Request =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/request_icon.svg";
const RequestSm =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/request_icon_sm.svg";
const DashLine =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/line_dash.png";
const Msg =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/msg.png";
const Pulse =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/pulse_alert.png";

export const SideBarItemObj = [
  {
    icon: MyProfile,
    text: "My Profile",
    iconSm: ProfileSm,
    textSm: "My Profile",
    page: "/dashboard/my-profile",
    title: "Welcome to your profile page",
  },
  // {
  //   icon: Matches,
  //   text: "Matches",
  //   iconSm: MatchesSm,
  //   textSm: "Matches",
  //   page: "/dashboard/matches",
  //   title: "Matches",
  // },
  {
    icon: Request,
    text: "Discover",
    iconSm: RequestSm,
    textSm: "Discover",
    page: "/dashboard/friend-request",
    title: "Discover",
  },
  {
    icon: Message,
    text: "Chat with a handler",
    iconSm: MessageSm,
    textSm: "Chat with a handler",
    page: "/dashboard/handler-chat",
    title: "Chat",
  },
  {
    icon: Profile,
    text: "Handler Suggestions",
    iconSm: ProfileSm,
    textSm: "Handler Suggestions",
    page: "/dashboard/profile-suggestion",
    title: "Suggestions by your handler",
  },
  {
    icon: Msg,
    text: "Messenger",
    iconSm: ProfileSm,
    textSm: "Messenger",
    page: "/dashboard/messages",
    title: "Chat Messenger",
  },
   {
    icon: ASSETS.blindicon,
    text: "Blind Dates",
    iconSm: ASSETS.blindicon,
    textSm: "Blind Dates",
    page: "/dashboard/blind-date",
    title: "Blind Dates",
  },
   {
    icon: ASSETS.sub,
    text: "Subscription",
    iconSm: ASSETS.sub,
    textSm: "Subscription",
    page: "/dashboard/choose-a-plan",
    title: "Subscriptions",
  },
];
export const SideBarItemObjHandler = [
  {
    icon: DashLine,
    text: "Handler's Dashboard",
    iconSm: DashLine,
    textSm: "Handler's Dashboard",
    page: "/dashboard/handler",
    title: "Handler's Dashboard",
  },
  {
    icon: Profile,
    text: "Add User",
    iconSm: Profile,
    textSm: "Add User",
    page: "/dashboard/handler/add-user",
    title: "Add User",
  },
  {
    icon: Message,
    text: "Chats",
    iconSm: MessageSm,
    textSm: "Chats",
    page: "/dashboard/handler/chat",
    title: "Chats",
  },
  {
    icon: ASSETS.BdVid,
    text: "Blind Dates",
    iconSm: ASSETS.BdVid,
    textSm: "Blind Dates",
    page: "/dashboard/handler/blind-dates",
    title: "Blind Dates",
  },
];
export const SideBarItemObjAdmin = [
  {
    icon: DashLine,
    text: "Admin's Dashboard",
    iconSm: DashLine,
    textSm: "Admin's Dashboard",
    page: "/dashboard/admin",
    title: "Admin's Dashboard",
  },
  // {
  //   icon: Message,
  //   text: "Chats",
  //   iconSm: MessageSm,
  //   textSm: "Chats",
  //   page: "/dashboard/handler/chat",
  //   title: "Chats",
  // },
  {
    icon: Pulse,
    text: "Violations",
    iconSm: Pulse,
    textSm: "Violations",
    page: "/dashboard/violations",
    title: "Violations",
  },
];
